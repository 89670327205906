import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Space, Table, TableProps, Tag, Typography, Tabs, TabsProps, Empty } from 'antd'
import { useState } from 'react'
import { IOperation } from 'interfaces'
import { formattedCurrencyAmount, formattedCorrectedPercent } from 'utils'
import { ShortId } from 'components/ShortId'
import { TextField } from '@refinedev/antd'
import DateField from 'components/DateField'
import { CopyText } from 'components/CopyText'
import { GatewayType } from 'components/GatewayType'
import { GatewayDirection } from 'components/GatewayDirection'
import { OperationKind } from 'components/OperationKind'
import { OperationStatus } from 'components/OperationStatus'
import { EventsList } from './EventsList'
import { TariffKind } from 'components/TariffKind'

const { Text } = Typography

const MoreInfo: React.FC<{ operation: IOperation }> = ({ operation }) => {
  const statusColor = (status: number): string => {
    if (status < 3) return 'orange'
    if (status === 3) return 'green'
    return 'red'
  }

  let tabsItems: TabsProps['items'] = []
  operation?.id &&
    tabsItems.push({
      key: '1',
      label: 'Actions',
      children: (
        <EventsList operationId={operation.id} />
      )
    })
  operation.auris.payment?.id &&
    tabsItems.push({
      key: '2',
      label: 'Auris Payment',
      children: (
        <Tag
          color={operation.auris.payment?.response?.error ? 'red' : 'geekblue'}
        >
          <pre>{JSON.stringify(operation.auris.payment, null, 2)}</pre>
        </Tag>
      )
    })
  operation.auris.payment_qr?.id &&
    tabsItems.push({
      key: '3',
      label: 'Auris Payment QR',
      children: (
        <Tag
          color={operation.auris.payment_qr?.response?.error ? 'red' : 'geekblue'}
        >
          <pre>{JSON.stringify(operation.auris.payment_qr, null, 2)}</pre>
        </Tag>
      )
    })
  operation.auris.withdraw?.id &&
    tabsItems.push({
      key: '4',
      label: 'Auris Withdraw',
      children: (
        <Tag
          color={operation.auris.withdraw?.response?.error ? 'red' : 'geekblue'}
        >
          <pre>{JSON.stringify(operation.auris.withdraw, null, 2)}</pre>
        </Tag>
      )
    })
  operation.auris.acquire?.id &&
    tabsItems.push({
      key: '5',
      label: 'Auris Acquire',
      children: (
        <Tag
          color={operation.auris.acquire?.response?.error ? 'red' : 'geekblue'}
        >
          <pre>{JSON.stringify(operation.auris.acquire, null, 2)}</pre>
        </Tag>
      )
    })
  operation.auris.confirm_otp?.id &&
    tabsItems.push({
      key: '6',
      label: 'Auris Confirm OTP',
      children: <Tag color={'orange'}><pre>{JSON.stringify(operation.auris.confirm_otp, null, 2)}</pre></Tag>
    })
  operation.auris.resend?.id &&
    tabsItems.push({
      key: '7',
      label: 'Auris Resend',
      children: <Tag color={'orange'}><pre>{JSON.stringify(operation.auris.resend, null, 2)}</pre></Tag>
    })
  operation.auris.auris_statuses.length &&
    tabsItems.push({
      key: '8',
      label: 'Auris Statuses',
      children: (
        <>
          {operation.auris.auris_statuses?.map((value) => (
            <Tag
              key={value?.id}
              color={statusColor(value?.response?.result?.status)}
            >
              <pre>{JSON.stringify(value, null, 2)}</pre>
            </Tag>
          ))}
        </>
      )
    })

  return (
    tabsItems.length ? (
      <Tabs
        type="card"
        defaultActiveKey="1"
        items={tabsItems}
      />
    ) : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<Text style={{ color: '#AAAAAA' }}>Нет данных</Text>}
      />
    ))
}

const AdminOperationsTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IOperation> }
> = ({ tableProps }) => {
  const t = useTranslate()

  const [expandedKey, setExpandedKey] = useState(String)
  const onExpand = (_: any, record: IOperation) => {
    expandedKey === record.id ? setExpandedKey('') : setExpandedKey(record.id)
  }

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      expandable={{
        expandedRowRender: (operation) => (
          <MoreInfo operation={operation} />
        ),
        onExpand,
        expandedRowKeys: [expandedKey]
      }}
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 20,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IOperation>
        ellipsis
        dataIndex="id"
        key="id"
        title={t('pages.admin_operations.fields.id')}
        render={(value) =>
          <ShortId value={value} />
        }
      />
      <Table.Column<IOperation>
        ellipsis
        dataIndex={['currency', 'code']}
        key="currency_code"
        title={t('pages.admin_operations.fields.currency')}
      />
      <Table.Column<IOperation>
        ellipsis
        dataIndex="kind"
        key="kind"
        title={t('pages.admin_operations.fields.kind')}
        render={(value) => <OperationKind kind={value} />}
      />
      <Table.Column<IOperation>
        ellipsis
        dataIndex="status"
        key="status"
        title={t('pages.admin_operations.fields.status')}
        render={(value) => <OperationStatus status={value} />}
      />
      <Table.Column<IOperation>
        ellipsis
        dataIndex="client_amount"
        key="client_amount"
        title={t('pages.admin_operations.fields.client_amount')}
        render={(value, record) => (
          <TextField style={{ fontWeight: 'bold' }} value={formattedCurrencyAmount(value, 2, record?.currency?.code)} />
        )}
      />
      <Table.Column<IOperation>
        dataIndex="gateway"
        key="gateway"
        title={t('pages.admin_operations.fields.gateway')}
        render={(value) =>
          <Space size={1} direction='vertical'>
            <GatewayDirection direction={value?.direction} />
            <GatewayType type={value?.payment_type} />
            <Tag>{value?.name}</Tag>
          </Space>
        }
      />
      <Table.Column<IOperation>
        dataIndex="payment_provider"
        key="payment_provider"
        title={t('pages.admin_operations.fields.payment_provider')}
        render={(value) => <Tag>{value?.name}</Tag>}
      />
      <Table.Column<IOperation>
        ellipsis
        dataIndex="provider_amount"
        key="provider_amount"
        title={t('pages.admin_operations.fields.provider_amount')}
        render={(value, record) => (
          <TextField value={formattedCurrencyAmount(value, 2, record?.currency?.code)} />
        )}
      />
      <Table.Column<IOperation>
        dataIndex="user"
        key="user"
        title={t('pages.admin_operations.fields.user')}
        render={(value) =>
          <Space direction='vertical'>
            <Tag><ShortId value={value?.id} /></Tag>
            <Tag color='geekblue'><CopyText value={value?.phone} style={{ fontSize: 12 }} /></Tag>
          </Space>
        }
      />
      <Table.Column<IOperation>
        dataIndex="fee"
        key="fee"
        title={t('pages.admin_operations.fields.fee')}
        render={(value, record) =>
          <Space direction='vertical'>
            {value?.status && <TextField value={formattedCurrencyAmount(value?.amount, 2, record?.currency?.code)} />}
            <Tag>{value?.status}</Tag>
          </Space>
        }
      />
      <Table.Column<IOperation>
        dataIndex="tariff"
        key="tariff"
        title={t('pages.admin_operations.fields.tariff')}
        render={(value) =>
          <Space direction='vertical'>
            <Tag><TextField value={formattedCorrectedPercent(value?.percentage_fee)} /></Tag>
            {value?.kind && <TariffKind kind={value?.kind} />}
          </Space>
        }
      />
      <Table.Column<IOperation>
        ellipsis
        dataIndex="account"
        key="account"
        title={t('pages.admin_operations.fields.account')}
        render={(value) => <Tag><ShortId value={value?.id} /></Tag>}
      />
      <Table.Column<IOperation>
        ellipsis
        dataIndex="created_at"
        key="created_at"
        title={t('pages.admin_operations.fields.created_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
      <Table.Column<IOperation>
        ellipsis
        dataIndex="updated_at"
        key="updated_at"
        title={t('pages.admin_operations.fields.updated_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
    </Table>
  )
}

export default AdminOperationsTable
