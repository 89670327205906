import React from 'react';
import { useGetIdentity, useTranslate } from '@refinedev/core'
import { Flex, Typography } from 'antd'
import { motion } from "framer-motion";
import { Link } from 'react-router-dom'

import { ICurrentUser } from 'interfaces';
import { Wrapper } from 'components/client_ui/Wrapper'
import { Card } from 'components/client_ui/Card'
import { CloseBtn } from 'components/client_ui/CloseBtn';
import { BackBtn } from 'components/client_ui/BackBtn';
import { PersonHeader } from 'components/client_ui/PersonHeader';

const { Text } = Typography

const RightIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_6887)">
        <path d="M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_120_6887">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const AutheticatorIcon: React.FC = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23" cy="23" r="22.5" fill="#303030" stroke="#E0EAF5" />
      <path d="M9.32923 22.9095C9.32923 30.5097 15.4904 36.6708 23.0906 36.6708C24.3997 36.6708 25.6662 36.488 26.8659 36.1465L18.6083 27.8889C18.434 27.732 18.2681 27.566 18.1111 27.3917C17.0418 26.2046 16.391 24.633 16.391 22.9095C16.391 19.2094 19.3905 16.2099 23.0906 16.2099C26.7906 16.2099 29.7902 19.2094 29.7902 22.9095H36.8519C36.8519 15.3093 30.6907 9.14816 23.0906 9.14816C15.4904 9.14816 9.32923 15.3093 9.32923 22.9095Z" fill="#646464" />
      <path fillRule="evenodd" clip-rule="evenodd" d="M18.1111 27.3917L18.6083 27.8889C18.434 27.732 18.2681 27.566 18.1111 27.3917Z" fill="#646464" />
      <path d="M1 23C1 35.1503 10.8497 45 23 45C26.6857 45 30.1596 44.0937 33.2112 42.4918L26.8659 36.1465C25.6662 36.488 24.3997 36.6708 23.0906 36.6708C15.4904 36.6708 9.32923 30.5097 9.32923 22.9095C9.32923 15.3093 15.4904 9.14816 23.0906 9.14816C30.6907 9.14816 36.8519 15.3093 36.8519 22.9095C36.8519 29.2005 32.6305 34.5055 26.8659 36.1465L33.2112 42.4918C40.2196 38.8128 45 31.4647 45 23C45 10.8497 35.1503 1 23 1C10.8497 1 1 10.8497 1 23Z" fill="#646464" />
      <path d="M38.5409 7.42821C35.6354 4.52841 31.9764 2.49939 27.9779 1.57056C23.9793 0.641737 19.8007 0.850165 15.9144 2.17228C12.0281 3.49439 8.58929 5.87745 5.98671 9.05198C3.38413 12.2265 1.72164 16.0659 1.18724 20.136C0.652828 24.206 1.26782 28.3445 2.96267 32.0832C4.65752 35.822 7.36462 39.0121 10.7779 41.2926C14.1911 43.5732 18.1743 44.8533 22.2771 44.9882C26.0807 45.1132 29.8465 44.2494 33.2086 42.4881L26.9264 36.2059C25.5132 36.6261 24.0349 36.8187 22.5473 36.7698C19.978 36.6853 17.4835 35.8837 15.346 34.4555C13.2085 33.0274 11.5133 31.0297 10.4519 28.6883C9.3905 26.3469 9.00537 23.7553 9.34004 21.2064C9.6747 18.6576 10.7158 16.2532 12.3456 14.2652C13.9755 12.2772 16.129 10.7849 18.5628 9.9569C20.9965 9.12894 23.6133 8.99842 26.1174 9.58008C28.6214 10.1617 30.9128 11.4324 32.7323 13.2484L38.5409 7.42821Z" fill="#AAAAAA" />
      <circle cx="23.0909" cy="40.654" r="1.8107" fill="#AAAAAA" />
      <circle cx="35.5735" cy="35.4829" r="1.8107" transform="rotate(-45 35.5735 35.4829)" fill="#AAAAAA" />
      <circle cx="23.0909" cy="5.34537" r="1.8107" fill="#AAAAAA" />
      <circle cx="10.6065" cy="10.5161" r="1.8107" transform="rotate(-45 10.6065 10.5161)" fill="#AAAAAA" />
      <circle cx="5.43607" cy="22.9998" r="1.8107" transform="rotate(-90 5.43607 22.9998)" fill="#AAAAAA" />
      <circle cx="10.6067" cy="35.4832" r="1.8107" transform="rotate(-135 10.6067 35.4832)" fill="#AAAAAA" />
      <rect x="20.9175" y="20.5557" width="22.0906" height="4.34568" rx="2.17284" fill="#AAAAAA" />
    </svg>
  );
};

export const TwoFAShow: React.FC = () => {
  const t = useTranslate()
  const { data: user } = useGetIdentity<ICurrentUser>()

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.6,
        ease: 'easeInOut',
      }}

      style={{
        position: 'relative',
        minHeight: '100vh',
        background: 'linear-gradient(258.2deg, #DDF7FF 0%, #C5DCFF 100%)',
      }}
    >
      <Wrapper
        style={{
          padding: '48px 8px 10px 8px',
        }}
      >
        <Card
          style={{
            position: 'relative',
            padding: 12,
            minHeight: 'calc(100vh - 58px)',
            boxShadow: '0px 0px 10px 0px #00000026',
            paddingBottom: 120,
          }}
        >
          <CloseBtn
            style={{
              position: 'absolute',
              top: 12,
              right: 12,
              zIndex: 1,
            }}
          />

          <BackBtn
            style={{
              position: 'absolute',
              bottom: 50,
              left: '50%',
              transform: 'translate(-50%)',
              zIndex: 1,
            }}
          />

          <Flex
            vertical
            gap={20}
          >
            {user?.phone && <PersonHeader user={user} />}
            <Flex
              vertical
              gap={24}
              style={{
                padding: 12,
              }}
            >
              <Flex
                vertical
                gap={20}
              >
                <Text
                  style={{
                    fontFamily: 'Jost',
                    fontSize: 12,
                    fontWeight: '700',
                    lineHeight: '17.34px',
                    letterSpacing: '0.1em',
                    textTransform: 'uppercase',
                    color: '#303030',
                  }}
                >
                  {t('pages.twofa.titles.twofa')}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Jost',
                    fontSize: 16,
                    fontWeight: '400',
                    lineHeight: '23.12px',
                    color: '#303030',
                  }}
                >
                  {t('pages.twofa.titles.twofa_info')}
                </Text>
              </Flex>
              <Flex
                vertical
                gap={32}
              >
                <Flex
                  vertical
                  gap={24}
                >
                  <Link
                    to='/twofa_switch'
                  >
                    <Flex
                      justify='space-between'
                      align='center'
                      gap={12}
                    >
                      <Flex
                        align='center'
                        gap={8}
                      >
                        <Flex
                          align='center'
                          gap={12}
                        >
                          <AutheticatorIcon />
                          <Flex
                            vertical
                            gap={2}
                          >
                            <Text
                              style={{
                                fontFamily: 'Jost',
                                fontSize: 14,
                                fontWeight: '400',
                                lineHeight: '20.23px',
                                color: '#303030',
                              }}
                            >
                              Google Autheticator
                            </Text>
                            {user && <Text
                              style={{
                                fontFamily: 'Jost',
                                fontSize: 10,
                                fontWeight: '400',
                                lineHeight: '14.45px',
                                color: user?.two_factor_verification_enabled ? '#00C364' : '#AAAAAA',
                              }}
                            >
                              {user?.two_factor_verification_enabled ? t('pages.twofa.titles.on') : t('pages.twofa.titles.off')}
                            </Text>}
                          </Flex>
                        </Flex>
                      </Flex>
                      <RightIcon />
                    </Flex>
                  </Link>
                </Flex>
                {/* <Flex
                  vertical
                  gap={24}
                >
                  <Text
                    style={{
                      fontFamily: 'Jost',
                      fontSize: 12,
                      fontWeight: '700',
                      lineHeight: '17.34px',
                      letterSpacing: '0.1em',
                      textTransform: 'uppercase',
                      color: '#303030',
                    }}
                  >
                    {t('pages.twofa.titles.request_confirmation')}
                  </Text>
                </Flex> */}
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Wrapper>
    </motion.div >
  )
}
