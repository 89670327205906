import { Flex, Typography } from "antd";
import { ForwardIcon, CheckIcon, CloseIcon } from "./icons";

const { Text } = Typography

export const Checker: React.FC<{
  text: string
  status?: number
}> = ({
  text,
  status = null
}) => {
    return (
      <Flex
        align='center'
        gap={4}
      >
        {!status && <ForwardIcon />}
        {status === 1 && <CloseIcon />}
        {status === 2 && <CheckIcon />}
        <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 14,
            fontWeight: '400',
            lineHeight: '18px',
            color: '#303030',
          }}
        >
          {text}
        </Text>
      </Flex>
    )
  }