import { IResourceComponentsProps, useTranslate, useList } from '@refinedev/core'
import { ListButton, Create, useForm, useSelect } from '@refinedev/antd'
import {
  Form,
  Space,
  Tag,
  InputNumber,
  Select,
  Row,
  Col,
  DatePicker,
  SelectProps
} from 'antd'
import { useEffect, useState } from 'react'
import { agreementKindsOptions } from 'utils'
import { IAgreement, IGateway, IMerchant, IAgent, IPaymentProvider } from 'interfaces'
import dayjs from 'dayjs'
import { GatewayType } from 'components/GatewayType'
import { GatewayDirection } from 'components/GatewayDirection'
import { GatewayStatus } from 'components/GatewayStatus'
import { AgreementKind } from 'components/AgreementKind'

export const AdminAgreementCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { form, formProps, saveButtonProps } = useForm<IAgreement>()

  const [currentKind, setCurrentKind] = useState<string>('');

  const kind = Form.useWatch('kind', form)

  useEffect(() => {
    setCurrentKind(kind)
    form.setFieldsValue({ gateway_id: undefined })
  }, [kind, form])

  const valid_from_not_used = Form.useWatch('valid_from_not_used', form)

  useEffect(() => {
    form.setFieldsValue({
      valid_from: valid_from_not_used ? valid_from_not_used.format('YYYY-MM-DD') : null,
    })
  }, [valid_from_not_used, form])

  const { data: gatewaysData } = useList<IGateway>({
    resource: 'admin/gateways',
    filters: [
      {
        field: "direction",
        operator: "eq",
        value: currentKind === 'payment_provider_income' ? 'income' : 'outcome',
      },
    ],
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })

  const gatewayOptions: SelectProps['options'] = gatewaysData?.data.map((gateway) => ({
    label: (
      <Space size={1}>
        <Tag>{gateway?.currency?.code}</Tag>
        <GatewayDirection direction={gateway?.direction} />
        <GatewayType type={gateway?.payment_type} />
        <GatewayStatus status={gateway?.status} />
        <Tag>{gateway?.name}</Tag>
      </Space>
    ),
    desc: (gateway?.currency?.code ?? '') + (gateway?.direction ?? '') +
      (gateway?.payment_type ?? '') + (gateway?.name ?? ''),
    value: gateway.id,
  }))

  const { data: merchantsData } = useList<IMerchant>({
    resource: 'admin/merchants',
    filters: [
      {
        field: 'short',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
  })

  const merchantOptions: SelectProps['options'] = merchantsData?.data.map((merchant) => ({
    label: (
      <Space size={1}>
        <Tag>{merchant?.currency?.code}</Tag>
        <Tag color='geekblue'>{merchant?.name}</Tag>
      </Space>
    ),
    desc: (merchant?.currency?.code ?? '') + (merchant?.name ?? ''),
    value: merchant.id,
  }))

  const { selectProps: agentsSelectProps } = useSelect<IAgent>({
    resource: 'admin/agents',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'short',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
  })

  const { selectProps: paymentProvidersSelectProps } = useSelect<IPaymentProvider>({
    resource: 'admin/payment_providers',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'short',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 10000,
    },
  })

  const headerButtons = () => {
    return (
      <ListButton resource="admin/agreements" />
    )
  }

  return (
    <Create
      headerButtons={headerButtons}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          valid_from_not_used: dayjs.utc().add(1, 'day'),
        }}
      >
        <Form.Item name="valid_from" rules={[{ required: true, },]} hidden />

        <Row gutter={10}>
          <Col md={8} xs={24}>
            <Form.Item
              name="kind"
              label={t('pages.admin_agreements.fields.kind')}
              rules={[{ required: true, },]}
            >
              <Select
                options={agreementKindsOptions}
                optionRender={(option) => <AgreementKind kind={option.data.value} />}
                labelRender={(option) => <AgreementKind kind={option.value} />}
              />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              name="valid_from_not_used"
              label={t('pages.admin_agreements.fields.valid_from')}
              rules={[{ required: true, },]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                minDate={dayjs.utc()}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              name="percent"
              label={t('pages.admin_agreements.fields.percent')}
              rules={[{ required: true, },]}
            >
              <InputNumber
                type="number"
                decimalSeparator="."
                precision={2}
                min={0}
                max={100}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        {(currentKind === 'merchant_income' || currentKind === 'merchant_outcome' ||
          currentKind === 'agent_income' || currentKind === 'agent_outcome') &&
          <Row gutter={10}>
            <Col md={12} xs={24}>
              <Form.Item
                name="merchant_id"
                label={t('pages.admin_agreements.fields.merchant')}
                rules={[{ required: true, },]}
              >
                <Select
                  defaultOpen
                  options={merchantOptions}
                  allowClear
                  showSearch
                  filterOption={(input, option: any) => {
                    return (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
                  }}
                />
              </Form.Item>
            </Col>
            {(currentKind === 'agent_income' || currentKind === 'agent_outcome') &&
              <Col md={12} xs={24}>
                <Form.Item
                  name="agent_id"
                  label={t('pages.admin_agreements.fields.agent')}
                  rules={[{ required: true, },]}
                >
                  <Select
                    {...agentsSelectProps}
                    allowClear
                    showSearch
                    filterOption={(input, option: any) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
            }
          </Row>
        }

        {(currentKind === 'payment_provider_income' || currentKind === 'payment_provider_outcome') &&
          <Row gutter={10}>
            <Col md={12} xs={24}>
              <Form.Item
                name="gateway_id"
                label={t('pages.admin_agreements.fields.gateway')}
                rules={[{ required: true, },]}
              >
                <Select
                  defaultOpen
                  options={gatewayOptions}
                  allowClear
                  showSearch
                  filterOption={(input, option: any) => {
                    return (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="payment_provider_id"
                label={t('pages.admin_agreements.fields.payment_provider')}
                rules={[{ required: true, },]}
              >
                <Select
                  {...paymentProvidersSelectProps}
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        }
      </Form>
    </Create>
  )
}
