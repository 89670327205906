import {
  useIsAuthenticated,
} from '@refinedev/core'

import { Typography, Flex } from 'antd'
import { motion } from "framer-motion";

import { Wrapper } from 'components/client_ui/Wrapper'
import { LogoBlack } from 'components/client_ui/LogoBlack';
import { PhoneForm } from './phone_form';
import { OtpForm } from './otp_form';
import { PasswordForm } from './password_form';
import { Buttons } from "./buttons";
import { Finish } from './finish';
import { LogoutBtn } from './logout_btn';
import { Welcome } from 'components/client_ui/Welcome';

const { Text } = Typography;

export const ForgotPassword = () => {
  const { data } = useIsAuthenticated()

  const isConfirmOtp = data?.redirectTo === '/forgot_confirm_otp_required' ? true : false;
  const isPassword = data?.redirectTo === '/forgot_password_required' ? true : false;
  const isFinish = data?.redirectTo === '/forgot_finish_required' ? true : false;
  const isPhone = !isConfirmOtp && !isPassword && !isFinish;

  const gap = isPhone ? 64 : (isPassword ? 98 : 198);

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.45,
        ease: 'easeInOut',
      }}
      style={{
        position: 'relative',
        minHeight: '100vh',
        background: '#FFFFFF',
      }}
    >
      <Welcome />
      {(isConfirmOtp || isPassword) && (
        <LogoutBtn
          style={{
            position: 'absolute',
            bottom: 50,
            left: '50%',
            transform: 'translate(-50%)',
            zIndex: 1,
          }}
        />
      )}
      <Wrapper
        style={{
          padding: '64px 42px',
        }}
      >
        {isFinish ? (
          <Finish />
        ) : (
          <Flex
            vertical
            align='center'
            gap={gap}
          >
            <LogoBlack />
            <Flex
              vertical
              align='center'
              gap={40}
            >
              {isPassword && <PasswordForm />}
              {isConfirmOtp && <OtpForm />}
              {isPhone && (
                <>
                  <Buttons isLogin={false} />
                  <PhoneForm />
                </>
              )}
              {isFinish && (<Finish />)}
            </Flex>
          </Flex>
        )}
      </Wrapper>
    </motion.div >
  )
}
