import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  usePermissions,
  useTranslate,
} from '@refinedev/core'

import { List, useTable } from '@refinedev/antd'
import { Form, Input, DatePicker, Segmented } from 'antd'
import { ITariff } from 'interfaces'
import { SearchOutlined } from '@ant-design/icons'
import TariffsTable from './list_table'
import { TariffKind } from 'components/TariffKind'

export const AdminTariffsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, searchFormProps, filters } = useTable<ITariff>({
    resource: 'admin/tariffs',

    onSearch: (params: any) => {
      const { id, valid_for_datetime, actual, kind } = params
      const tariffFilters: CrudFilters = []

      tariffFilters.push({
        field: 'id',
        operator: 'eq',
        value: id,
      })

      tariffFilters.push({
        field: 'valid_for_datetime',
        operator: 'eq',
        value: valid_for_datetime,
      })

      tariffFilters.push({
        field: 'actual',
        operator: 'eq',
        value: actual,
      })

      tariffFilters.push({
        field: 'kind',
        operator: 'eq',
        value: kind,
      })

      return tariffFilters
    },

    pagination: {
      pageSize: 20,
    },
  })

  const { data } = usePermissions<string>({
    v3LegacyAuthProviderCompatible: true,
  })

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List canCreate={data?.includes('admin')}>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          id: getDefaultFilter('id', filters),
          valid_for_datetime: getDefaultFilter('valid_for_datetime', filters),
          actual: getDefaultFilter('actual', filters),
          kind: getDefaultFilter('kind', filters),
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 10,
          }}
        >
          <Form.Item
            name="id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('pages.admin_tariffs.filter.id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="valid_for_datetime"
            style={{
              flexGrow: 1,
            }}
          >
            <DatePicker
              format="YYYY-MM-DD"
              placeholder={t('pages.admin_tariffs.filter.valid_for_datetime.placeholder')}
              allowClear
              style={{ width: '100%' }}
            />
          </Form.Item>
        </div>
        <div
          style={{
            overflow: 'auto'
          }}
        >
          <Form.Item name="actual">
            <Segmented
              options={[
                { label: 'ALL', value: undefined },
                { label: 'ACTUAL', value: 'true' },
              ]}
            />
          </Form.Item>
        </div>
        <div
          style={{
            overflow: 'auto',
          }}
        >
          <Form.Item name="kind">
            <Segmented
              options={[
                { label: 'ALL', value: undefined },
                { label: <TariffKind kind={'trusted_not_verified_not'} />, value: 'trusted_not_verified_not' },
                { label: <TariffKind kind={'trusted_verified_not'} />, value: 'trusted_verified_not' },
                { label: <TariffKind kind={'trusted_not_verified'} />, value: 'trusted_not_verified' },
                { label: <TariffKind kind={'trusted_verified'} />, value: 'trusted_verified' },
              ]}
            />
          </Form.Item>
        </div>
      </Form>
      <TariffsTable tableProps={tableProps} />
    </List>
  )
}
