import React from 'react'
import { useTranslate } from '@refinedev/core'
import { Flex, Typography } from 'antd'
import { Link } from 'react-router-dom'

const { Text } = Typography

const RightIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_6887)">
        <path d="M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_120_6887">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SubMenu: React.FC<{
  header?: string
  menu: any[]
  gap?: number
}> = ({
  header,
  menu,
  gap = 20
}) => {
    const t = useTranslate()

    return (
      <Flex
        vertical
        gap={gap}
        style={{

        }}
      >
        {header &&
          <Text
            style={{
              fontFamily: 'Jost',
              fontSize: 12,
              fontWeight: '700',
              lineHeight: '17.34px',
              letterSpacing: '0.1em',
              textTransform: 'uppercase',
              color: '#303030',
            }}
          >
            {header}
          </Text>
        }

        {menu?.map((m) =>
          <Link
            key={m?.name}
            to={m?.to}
            style={{
              height: 24,
            }}
          >
            <Flex
              justify='space-between'
              align='center'
            >
              <Text
                style={{
                  fontFamily: 'Jost',
                  fontSize: 16,
                  fontWeight: '400',
                  lineHeight: '23.12px',
                  color: '#303030',
                }}
              >
                {m?.name}
              </Text>
              {m?.icon && <RightIcon />}
            </Flex>
          </Link>
        )}
      </Flex>
    )
  }