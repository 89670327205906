import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, TableProps, Tag, Space, Typography } from 'antd'
import { useTable } from '@refinedev/antd'
import { useState } from 'react'
import { IAccount, IAccountEntry } from 'interfaces'
import { formattedCurrencyAmount } from 'utils'
import { ShortId } from 'components/ShortId'
import { TextField } from '@refinedev/antd'
import DateField from 'components/DateField'
import { CopyText } from 'components/CopyText'
import { AdminAccountEntriesTable } from '../account_entries/list_table'

const MoreInfo: React.FC<{ account: IAccount }> = ({ account }) => {
  const { tableProps } = useTable<IAccountEntry>({
    resource: 'admin/account_entries',
    filters: {
      permanent: [
        {
          field: 'account_id',
          operator: 'eq',
          value: account.id,
        },
      ]
    },
    pagination: {
      pageSize: 20,
    },
  })

  return (
    <AdminAccountEntriesTable tableProps={tableProps} />
  )
}

const AdminAccountsTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IAccount> }
> = ({ tableProps }) => {
  const t = useTranslate()

  const [expandedKey, setExpandedKey] = useState(String)
  const onExpand = (_: any, record: IAccount) => {
    expandedKey === record.id ? setExpandedKey('') : setExpandedKey(record.id)
  }

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      expandable={{
        expandedRowRender: (account) => (
          <MoreInfo account={account} />
        ),
        onExpand,
        expandedRowKeys: [expandedKey]
      }}
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 20,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IAccount>
        ellipsis
        dataIndex="id"
        key="id"
        title={t('pages.admin_accounts.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IAccount>
        ellipsis
        dataIndex={['currency', 'code']}
        key="currency_code"
        title={t('pages.admin_accounts.fields.currency')}
      />
      <Table.Column<IAccount>
        ellipsis
        dataIndex="balance"
        key="balance"
        width={150}
        title={t('pages.admin_accounts.fields.balance')}
        render={(value, record) => (
          <TextField value={formattedCurrencyAmount(value, 2, record?.currency?.code)} />
        )}
      />
      <Table.Column<IAccount>
        dataIndex="user"
        key="user"
        title={t('pages.admin_accounts.fields.user')}
        render={(value) =>
          <Space direction='vertical' size={1}>
            <Tag><ShortId value={value?.id} /></Tag>
            {value?.id && <Tag color='geekblue'><CopyText value={value?.phone} style={{ fontSize: 12 }} /></Tag>}
          </Space>
        }
      />
      <Table.Column<IAccount>
        dataIndex="merchant"
        key="merchant"
        title={t('pages.admin_accounts.fields.merchant')}
        render={(value) =>
          <Space direction='vertical' size={1}>
            <Tag><ShortId value={value?.id} /></Tag>
            {value?.id && <Tag color='purple'>{value?.name}</Tag>}
          </Space>
        }
      />
      <Table.Column<IAccount>
        dataIndex="payment_provider"
        key="payment_provider"
        title={t('pages.admin_accounts.fields.payment_provider')}
        render={(value) =>
          <Space direction='vertical' size={1}>
            <Tag><ShortId value={value?.id} /></Tag>
            {value?.id && <Tag color='blue'>{value?.name}</Tag>}
          </Space>
        }
      />
      <Table.Column<IAccount>
        dataIndex="agent"
        key="agent"
        title={t('pages.admin_accounts.fields.agent')}
        render={(value) =>
          <Space direction='vertical' size={1}>
            <Tag><ShortId value={value?.id} /></Tag>
            {value?.id && <Tag>{value?.name}</Tag>}
          </Space>
        }
      />
      <Table.Column<IAccount>
        ellipsis
        dataIndex="created_at"
        key="created_at"
        title={t('pages.admin_accounts.fields.created_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
      <Table.Column<IAccount>
        ellipsis
        dataIndex="updated_at"
        key="updated_at"
        title={t('pages.admin_accounts.fields.updated_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
    </Table>
  )
}

export default AdminAccountsTable
