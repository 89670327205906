import dayjs from 'dayjs'
import { DateField as AntdDateField } from '@refinedev/antd'
import { Typography } from 'antd'

const { Text } = Typography

export const FormatedDate: React.FC<{
  value?: string
  format?: string
  ellipsis?: boolean
  style?: React.CSSProperties
}> = ({ value, format = 'YYYY.MM.DD HH:mm', ellipsis, style = {} }) => {
  const dayjsValue = dayjs.utc(value).tz()

  if (!value) {
    return (
      <Text style={{ ...style, verticalAlign: 'bottom' }}>…</Text>
    )
  }

  return (
    <AntdDateField
      value={dayjsValue}
      locales={dayjs.locale()}
      format={format}
      ellipsis
      style={style}
    />
  )
}

