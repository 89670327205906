import {
  useActiveAuthProvider,
  useTranslate,
  useRegister,
  RegisterFormTypes
} from "@refinedev/core";

import { Flex, Form, Input, Typography } from "antd";
import { formatPhone, checkPhone } from "components/client_ui/utils";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import { SubmitButton } from "components/client_ui/SubmitButton";

const { Text } = Typography

export const PhoneForm: React.FC = () => {
  const t = useTranslate()

  const authProvider = useActiveAuthProvider();
  const { mutate: forgot_password, isLoading } = useRegister<RegisterFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy)
  });

  const [form] = Form.useForm()
  const values = Form.useWatch([], form);

  useEffect(() => {
    const phone = form.getFieldValue('phone');
    form.setFieldValue('phone', formatPhone(phone));
  }, [form, values]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values) => forgot_password(values)}
      requiredMark={false}
      initialValues={{}}
    >
      <Form.Item name="forgot_password" initialValue={'true'} hidden />
      <Flex
        vertical
        align='center'
        gap={40}
      >
        <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 16,
            fontWeight: '700',
            lineHeight: '23.12px',
            color: '#303030',
          }}
        >
          {t('pages.forgot_password.titles.password_recovery')}
        </Text>
        <Flex
          vertical
          align='center'
          gap={8}
        >
          <Flex
            vertical
            gap={8}
            align='center'
          >
            <Text
              style={{
                height: 20,
                fontFamily: 'Jost',
                fontSize: 14,
                fontWeight: '500',
                lineHeight: '20.23px',
                color: '#303030',
              }}
            >
              {t('pages.forgot_password.fields.phone')}
            </Text>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true, message: '',
                },
                {
                  validator: checkPhone, message: '',
                }
              ]}
              style={{
                textAlign: 'center',
                fontFamily: 'Jost',
                fontSize: 20,
                fontWeight: '800',
                lineHeight: '28.9px',
                color: '#303030',
              }}
            >
              <Input
                id='phone'
                placeholder='+7'
                maxLength={24}
                style={{
                  textAlign: 'center',
                  height: 45,
                  width: 306,
                  padding: '8px 16px',
                  borderRadius: 24,
                  border: '1px solid #EEEEEE',
                  fontFamily: 'Jost',
                  fontSize: 20,
                  fontWeight: '800',
                  lineHeight: '28.9px',
                  color: '#303030',
                  outline: 0,
                  outlineOffset: 0,
                }}
              />
            </Form.Item>
          </Flex>
          <Link
            to='/login'
            style={{
              height: 17,
            }}
          >
            <Text
              style={{
                height: 17,
                fontFamily: 'Jost',
                fontSize: 12,
                fontWeight: '400',
                lineHeight: '17.34px',
                color: '#5D59F6',
              }}
            >
              {t('pages.forgot_password.buttons.already_have_account')}
            </Text>
          </Link>
        </Flex>
        <Flex
          vertical
          align='center'
          gap={24}
        >
          <Text
            style={{
              fontFamily: 'Jost',
              fontSize: 14,
              fontWeight: '400',
              lineHeight: '20.23px',
              color: '#303030',
            }}
          >
            {t('pages.forgot_password.titles.we_will_send_code')}
          </Text>
          <Form.Item>
            <SubmitButton
              isLoading={isLoading}
              form={form}
            />
          </Form.Item>
        </Flex>
      </Flex>
    </Form>
  )
}