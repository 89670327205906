import {
  useActiveAuthProvider,
  useTranslate,
  useRegister,
  RegisterFormTypes
} from "@refinedev/core";
import React, { useState } from "react";
import { Flex, Form, Input, Typography } from "antd";
import { SubmitButton } from "components/client_ui/SubmitButton";
import { Checker } from "components/client_ui/Checker";

const { Text } = Typography

export const PasswordForm: React.FC = () => {
  const t = useTranslate()

  const authProvider = useActiveAuthProvider();
  const { mutate: forgot_password, isLoading } = useRegister<RegisterFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy)
  });

  const [form] = Form.useForm()

  const [capital, setCapital] = useState<number>(0);
  const [digit, setDigit] = useState<number>(0);
  const [special, setSpecial] = useState<number>(0);
  const [length, setLength] = useState<number>(0);
  const [confirmation, setConfirmation] = useState<number>(0);

  const checkPassword = (_: any, value: string) => {
    if (!value) {
      setCapital(0);
      setDigit(0);
      setSpecial(0);
      setLength(0);
      return Promise.resolve();
    }

    const capital = value.match(/[A-Z]/);
    const digit = value.match(/[0-9]/);
    const special = value.match(/[@#$%^&+=!*()\-~]/);
    const length = value.length >= 8

    capital ? setCapital(2) : setCapital(1);
    digit ? setDigit(2) : setDigit(1);
    special ? setSpecial(2) : setSpecial(1);
    length ? setLength(2) : setLength(1);

    if (capital && digit && special && length) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(''));
  };

  const checkConfirmation = (_: any, value: string) => {
    if (!value) {
      setConfirmation(0);
      return Promise.resolve();
    }
    if (form.getFieldValue('password') === value) {
      setConfirmation(2);
      return Promise.resolve();
    }
    setConfirmation(1);
    return Promise.reject(new Error(''));
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values) => forgot_password(values)}
      requiredMark={false}
      initialValues={{}}
    >
      <Form.Item name="forgot_password" initialValue={'true'} hidden />
      <Flex
        vertical
        align='center'
        gap={48}
      >
        <Flex
          vertical
          align='center'
        // gap={12}
        >
          <Flex
            vertical
            gap={8}
            align='center'
          >
            <Text
              style={{
                height: 20,
                fontFamily: 'Jost',
                fontSize: 14,
                fontWeight: '500',
                lineHeight: '20.23px',
                color: '#303030',
              }}
            >
              {t('pages.forgot_password.fields.choose_password')}
            </Text>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true, message: '',
                },
                {
                  validator: checkPassword, message: '',
                },
              ]}
              style={{
                textAlign: 'center',
                fontFamily: 'Jost',
                fontSize: 20,
                fontWeight: '800',
                lineHeight: '28.9px',
                color: '#303030',
              }}
            >
              <Input
                key='password'
                type='password'
                autoComplete="off"
                maxLength={64}
                placeholder='●●●●●●●●●●●●●●●'
                style={{
                  textAlign: 'center',
                  height: 45,
                  width: 306,
                  padding: '8px 16px',
                  borderRadius: 24,
                  border: '1px solid #EEEEEE',
                  fontFamily: 'Jost',
                  fontSize: 20,
                  fontWeight: '800',
                  lineHeight: '28.9px',
                  color: '#303030',
                  outline: 0,
                  outlineOffset: 0,
                }}
              />
            </Form.Item>
          </Flex>
          <Flex
            vertical
            gap={8}
            align='center'
          >
            <Text
              style={{
                height: 20,
                fontFamily: 'Jost',
                fontSize: 14,
                fontWeight: '500',
                lineHeight: '20.23px',
                color: '#303030',
              }}
            >
              {t('pages.forgot_password.fields.confirm_password')}
            </Text>
            <Form.Item
              name='confirm_password'
              dependencies={['password']}
              rules={[
                {
                  required: true, message: '',
                },
                {
                  validator: checkConfirmation, message: '',
                },
              ]}
              style={{
                textAlign: 'center',
                fontFamily: 'Jost',
                fontSize: 20,
                fontWeight: '800',
                lineHeight: '28.9px',
                color: '#303030',
              }}
            >
              <Input
                key='confirm_password'
                type='password'
                autoComplete="off"
                maxLength={64}
                placeholder='●●●●●●●●●●●●●●●'
                style={{
                  textAlign: 'center',
                  height: 45,
                  width: 306,
                  padding: '8px 16px',
                  borderRadius: 24,
                  border: '1px solid #EEEEEE',
                  fontFamily: 'Jost',
                  fontSize: 20,
                  fontWeight: '800',
                  lineHeight: '28.9px',
                  color: '#303030',
                  outline: 0,
                  outlineOffset: 0,
                }}
              />
            </Form.Item>
          </Flex>
          <Flex
            vertical
            gap={8}
          >
            <Checker status={capital} text={t('pages.forgot_password.titles.password_capital_char')} />
            <Checker status={digit} text={t('pages.forgot_password.titles.password_digit')} />
            <Checker status={special} text={t('pages.forgot_password.titles.password_special_char')} />
            <Checker status={length} text={t('pages.forgot_password.titles.password_length')} />
            <Checker status={confirmation} text={t('pages.forgot_password.titles.password_confirmation')} />
          </Flex>
        </Flex>
        <Flex
          vertical
          align='center'
          gap={24}
        >
          <Form.Item>
            <SubmitButton
              isLoading={isLoading}
              form={form}
            />
          </Form.Item>
        </Flex>
      </Flex>
    </Form>
  )
}