import React from "react";
import { useTranslate, useList } from '@refinedev/core'
import { Flex, Typography, Skeleton, Space } from 'antd'
import { DateField } from '@refinedev/antd'
import { useNavigate } from "react-router-dom"

import { InvoiceIcon } from "./icons";
import { formatAmount, formatCurrency, statusColor, compareDays } from "../utils";
import { IInvoice } from 'interfaces';

const { Text } = Typography

const InvoiceDate: React.FC<{
  created_at: string
  style?: React.CSSProperties
}> = ({
  created_at,
  style = {}
}) => {
    return (
      <Flex
        justify='end'
        align='center'
        style={Object.assign({
          height: 23,
        }, style)}
      >
        <DateField
          ellipsis
          value={created_at}
          format='MMMM, D'
          style={{
            fontFamily: 'Jost',
            fontSize: 12,
            fontWeight: '400',
            lineHeight: '17.34px',
            color: '#303030',
            textAlign: 'right',
            textTransform: 'capitalize'
          }}
        />
      </Flex>
    )
  }

const Amount: React.FC<{
  amount: string
  currency: string
}> = ({
  amount,
  currency
}) => {
    return (
      <div
        style={{
          height: 20,
          textAlign: 'right',
        }}
      >
        <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 14,
            fontWeight: '700',
            lineHeight: '20.23px',
            color: '#303030',
          }}
        >
          <Space size={1}>
            {formatAmount(amount)}
            {formatCurrency(currency)}
          </Space>
        </Text>
      </div>
    )
  }

const Invoice: React.FC<{ data: IInvoice }> = ({ data, }) => {
  const t = useTranslate()
  const navigate = useNavigate()

  return (
    <Flex
      wrap
      justify='space-between'
      align='center'
      onClick={() => navigate('/invoices/show/' + data.id)}
      style={{
        cursor: 'pointer'
      }}
    >
      <div
        style={{
          height: 44,
          width: 180,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <span
            style={{
              display: 'inline-block',
              width: 44,
              height: 44,
              padding: 10,
              borderRadius: 50,
              background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
            }}
            children={<InvoiceIcon />}
          />
          <div
            style={{
              height: 34,
            }}
          >
            <div
              style={{
                height: 20,
              }}
            >
              <Text
                ellipsis
                style={{
                  width: 140,
                  fontFamily: 'Jost',
                  fontSize: 14,
                  fontWeight: '400',
                  lineHeight: '20.23px',
                  color: '#303030',
                }}
              >
                {data.merchant.name}
              </Text>
            </div>
            <div
              style={{
                height: 14,
              }}
            >
              <Text
                style={{
                  fontFamily: 'Jost',
                  fontSize: 10,
                  fontWeight: '400',
                  lineHeight: '14.45px',
                  color: statusColor(data.status),
                }}
              >
                {t('components.invoices_list.statuses.' + data.status)}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Amount
        amount={data.amount}
        currency={data?.merchant?.currency?.code}
      />
    </Flex>
  )
}

export const InvoicesList: React.FC<{ count?: number }> = ({ count = 50 }) => {
  const { data: invoicesData, isLoading, isError } = useList<IInvoice>({
    resource: 'invoices',
    pagination: {
      current: 1,
      pageSize: count,
    },
  })
  const invoices = invoicesData?.data ?? [];

  const listInvoices = invoices?.map((record, idx) =>
    <div key={record.id}>
      {compareDays(record.created_at, invoices[idx - 1]?.created_at) !== true && (
        <InvoiceDate
          created_at={record.created_at}
          style={{
            marginTop: idx ? 12 : 0,
            marginBottom: 24,
          }}
        />
      )}

      <Invoice data={record} />
    </div>
  )

  return (
    <>
      {isLoading ? (
        <Skeleton
          active
          style={{
            marginTop: 45,
          }}
        />
      ) : (
        <Flex
          vertical
          gap={12}
        >
          {listInvoices}
        </Flex>
      )}
    </>
  )
}