import React from 'react';
import { Input } from 'antd';
import { RUBIcon } from './icons';
import { formatPhone } from 'components/client_ui/utils';

interface CustomInputProps {
  value: string;
  onChange: (value: string) => void;
  currency?: string;
  placeholder?: string;
  maxLength?: number;
  style?: React.CSSProperties;
  disabled?: boolean
}

export const CurrencyIcon: React.FC<{ currency?: string; }> = ({ currency }) => {
  switch (currency?.toUpperCase()) {
    case 'RUB':
      return <RUBIcon />
    default:
      return null
  }
}

export const InputAmount = (props: CustomInputProps) => {
  const { value, onChange, currency, style } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^\d+(\.\d{0,2})?$/;
    if (reg.test(inputValue) || inputValue === '') {
      onChange(inputValue);
    }
  };

  return (
    <Input
      {...props}
      value={value}
      onChange={handleChange}
      // variant='borderless'
      suffix={<CurrencyIcon currency={currency} />}
      style={Object.assign({
        height: 45,
        padding: '8px 16px',
        borderRadius: 24,
        border: '1px solid #EEEEEE',
        fontFamily: 'Jost',
        fontSize: 20,
        fontWeight: '400',
        lineHeight: '28.9px',
        color: '#303030',
        outline: 0,
        outlineOffset: 0,
      }, style)}
    />
  );
};

export const InputCardNumber = (props: CustomInputProps) => {
  const { value, onChange, currency, style } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const cleaned = inputValue.replace(/\D/g, '')

    if (cleaned?.match(/^\d{5,8}$/)) {
      onChange(
        cleaned.replace(/^(\d{4})(\d{1,4})/, '$1 $2')
      );
    } else if (cleaned?.match(/^\d{9,12}$/)) {
      onChange(
        cleaned.replace(/^(\d{4})(\d{4})(\d{1,4})/, '$1 $2 $3')
      );
    } else if (cleaned?.match(/^\d{13,16}$/)) {
      onChange(
        cleaned.replace(/^(\d{4})(\d{4})(\d{4})(\d{1,4})/, '$1 $2 $3 $4')
      );
    } else {
      onChange(cleaned);
    }
  };

  return (
    <Input
      {...props}
      value={value}
      onChange={handleChange}
      suffix={<CurrencyIcon currency={currency} />}
      style={Object.assign({
        height: 45,
        padding: '8px 16px',
        borderRadius: 24,
        border: '1px solid #EEEEEE',
        fontFamily: 'Jost',
        fontSize: 20,
        fontWeight: '400',
        lineHeight: '28.9px',
        color: '#303030',
        outline: 0,
        outlineOffset: 0,
      }, style)}
    />
  );
};

export const InputPhoneNumber = (props: CustomInputProps) => {
  const { value, onChange, style } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    onChange(formatPhone(inputValue));
  };

  return (
    <Input
      {...props}
      value={value}
      onChange={handleChange}
      maxLength={24}
      style={Object.assign({
        height: 45,
        padding: '8px 16px',
        borderRadius: 24,
        border: '1px solid #EEEEEE',
        fontFamily: 'Jost',
        fontSize: 20,
        fontWeight: '400',
        lineHeight: '28.9px',
        color: '#303030',
        outline: 0,
        outlineOffset: 0,
      }, style)}
    />
  );
};

export const InputHolderName = (props: CustomInputProps) => {
  const { value, onChange, style } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    onChange(inputValue?.toUpperCase());
  };

  return (
    <Input
      {...props}
      value={value}
      onChange={handleChange}
      style={Object.assign({
        height: 45,
        padding: '8px 16px',
        borderRadius: 24,
        border: '1px solid #EEEEEE',
        fontFamily: 'Jost',
        fontSize: 20,
        fontWeight: '400',
        lineHeight: '28.9px',
        color: '#303030',
        outline: 0,
        outlineOffset: 0,
      }, style)}
    />
  );
};

export const InputAccountNumber = (props: CustomInputProps) => {
  const { value, onChange, style } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const cleaned = inputValue.replace(/\D/g, '')

    if (cleaned?.match(/^\d{0,20}$/)) {
      onChange(cleaned);
    }
  };

  return (
    <Input
      {...props}
      value={value}
      onChange={handleChange}
      autoComplete='off'
      style={Object.assign({
        height: 45,
        padding: '8px 16px',
        borderRadius: 24,
        border: '1px solid #EEEEEE',
        fontFamily: 'Jost',
        fontSize: 20,
        fontWeight: '400',
        lineHeight: '28.9px',
        color: '#303030',
        outline: 0,
        outlineOffset: 0,
      }, style)}
    />
  );
};

export const InputBic = (props: CustomInputProps) => {
  const { value, onChange, style } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const cleaned = inputValue.replace(/\D/g, '')

    if (cleaned?.match(/^\d{0,9}$/)) {
      onChange(cleaned);
    }
  };

  return (
    <Input
      {...props}
      value={value}
      onChange={handleChange}
      autoComplete='off'
      style={Object.assign({
        height: 45,
        padding: '8px 16px',
        borderRadius: 24,
        border: '1px solid #EEEEEE',
        fontFamily: 'Jost',
        fontSize: 20,
        fontWeight: '400',
        lineHeight: '28.9px',
        color: '#303030',
        outline: 0,
        outlineOffset: 0,
      }, style)}
    />
  );
};