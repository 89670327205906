import React from 'react'
import { IResourceComponentsProps, useShow, useCan, useTranslate } from '@refinedev/core'
import { ListButton, EditButton, Show } from '@refinedev/antd'
import { Descriptions, Skeleton } from 'antd'

import { IAgent } from 'interfaces'
import { CopyText } from 'components/CopyText'
import DateField from 'components/DateField'

export const AgentShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IAgent>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const headerButtons = () => {
    return (
      <>
        <ListButton
          resource="admin/agents"
          title={t('agents.agents')}
        />
        {record &&
          <EditButton
            type='primary'
            resource="admin/agents"
            recordItemId={record.id}
            title={t('buttons.edit')}
          />
        }
      </>
    )
  }

  return (
    <>
      <Show
        headerButtons={headerButtons}
        resource="admin/agents"
        isLoading={isLoading}
        title={record?.name || ''}
      >
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Descriptions column={1}>
            <Descriptions.Item label={t('pages.agents.fields.id')}>
              <CopyText value={record?.id} />
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.agents.fields.created_at')}>
              <DateField value={record?.created_at} />
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.agents.fields.name')}>
              {record?.name}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Show>
    </>
  )
}
