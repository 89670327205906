export const formatPhone = (phone: string): string => {
  if (!phone)
    return '';

  const cleaned = phone ? phone.replace(/\D/g, '') : '';

  if (cleaned.match(/^[7-8]\d{10}$/))
    return cleaned.replace(/^(\d{1})(\d{3})(\d{3})(\d{4})$/, '+7 $2 $3 $4');

  if (cleaned.match(/^[1-69]\d{10}$/))
    return cleaned.replace(/^(\d{1})(\d{2})(\d{4})(\d{4})$/, '+$1 $2 $3 $4');

  if (cleaned.match(/^[1-69]\d{11,12}$/))
    return cleaned.replace(/^(\d{2})(\d{4})(\d{6,7})$/, '+$1 $2 $3');

  return cleaned.match(/^[1-9]\d{0,12}$/) ? '+' + cleaned : '+';
}

export const checkPhone = (_: any, phone: string) => {
  if (!phone)
    return Promise.resolve();

  const cleaned = phone ? phone.replace(/\D/g, '') : '';

  if (cleaned.match(/^([7]\d{10}|[1-689]\d{10,12})$/)) return Promise.resolve();

  return Promise.reject(new Error(''));
};

export const luhnValidate = (_: any, cardNumber: string) => {
  const cleaned = cardNumber.replace(/\D/g, '');

  if (!cleaned)
    return Promise.resolve();
  if (cleaned.length !== 16)
    return Promise.reject(new Error(''));

  const arr = `${cleaned}`
    .split('')
    .reverse()
    .map(x => Number.parseInt(x));

  const lastDigit = arr.shift();
  if (lastDigit === undefined)
    return Promise.reject(new Error(''));

  let sum = arr.reduce((acc, val, i) =>
    i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val), 0);

  sum += lastDigit;

  if (sum % 10 === 0)
    return Promise.resolve();

  return Promise.reject(new Error(''));
};

export const formatAmount = (amount: string | bigint | number | null): string => {
  return amount ? new Intl.NumberFormat("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(amount)) : ''
}

export const formatCurrency = (currency: string): string => {
  switch (currency?.toUpperCase()) {
    case 'RUB':
      return '₽';
    default:
      return currency;
  }
}

export const compareDays = (date1: string, date2: string): boolean => {
  if (!date1 || !date2) {
    return false
  }

  const d1 = new Date(date1)
  const d2 = new Date(date2)

  if (!d1.valueOf() || !d2.valueOf()) {
    return false
  }

  if (d1.getFullYear() !== d2.getFullYear()) {
    return false
  }
  if (d1.getMonth() !== d2.getMonth()) {
    return false
  }
  if (d1.getDate() !== d2.getDate()) {
    return false
  }

  return true
}

export const statusColor = (status: string): string => {
  switch (status.toLowerCase()) {
    case 'approved':
      return '#00C364';
    case 'successfully':
      return '#00C364';
    case 'verified':
      return '#00C364';
    case 'not_verified':
      return '#F28754';
    case 'cancelled':
      return '#F28754';
    case 'rejected':
      return '#F28754';
    case 'wasted':
      return '#F28754';
    case 'expired':
      return '#F28754';
    case 'paid':
      return '#00C364';
    case 'pending':
      return '#5D59F6';
    case 'created':
      return '#5D59F6';
    default:
      return '#303030';
  }
}

export const nspkCodes = [
  { value: '100000000111', label: 'Сбербанк' },
  { value: '100000000004', label: 'Т-Банк' },
  { value: '110000000005', label: 'Банк ВТБ' },
  { value: '100000000008', label: 'АЛЬФА-БАНК' },
  { value: '100000000007', label: 'Райффайзенбанк' },
  { value: '100000000015', label: 'Банк ОТКРЫТИЕ' },
  { value: '100000000001', label: 'Газпромбанк' },
  { value: '100000000010', label: 'Промсвязьбанк' },
  { value: '100000000013', label: 'Совкомбанк' },
  { value: '100000000012', label: 'РОСБАНК' },
  { value: '100000000020', label: 'Россельхозбанк' },
  { value: '100000000025', label: 'МОСКОВСКИЙ КРЕДИТНЫЙ БАНК' },
  { value: '100000000030', label: 'ЮниКредит Банк' },
  { value: '100000000003', label: 'Банк Синара' },
  { value: '100000000043', label: 'Газэнергобанк' },
  { value: '100000000028', label: 'АКБ АВАНГАРД' },
  { value: '100000000086', label: 'ПНКО ЭЛПЛАТ' },
  { value: '100000000011', label: 'РНКБ Банк' },
  { value: '100000000044', label: 'Экспобанк' },
  { value: '100000000049', label: 'Банк ВБРР' },
  { value: '100000000095', label: 'АБ РОССИЯ' },
  { value: '100000000900', label: 'ДБО Фактура' },
  { value: '100000000056', label: 'КБ Хлынов' },
  { value: '100000000053', label: 'Бланк банк' },
  { value: '100000000121', label: 'КБ Солидарность' },
  { value: '100000000082', label: 'Банк ДОМ.РФ' },
  { value: '100000000127', label: 'Хакасский муниципальный банк' },
  { value: '100000000017', label: 'МТС-Банк' },
  { value: '100000000087', label: 'Банк ПСКБ' },
  { value: '100000000052', label: 'Банк Левобережный' },
  { value: '100000000006', label: 'АК БАРС БАНК' },
  { value: '100000000098', label: 'КБ РостФинанс' },
  { value: '100000000092', label: 'БыстроБанк' },
  { value: '100000000229', label: 'МС Банк Рус' },
  { value: '100000000027', label: 'Кредит Европа Банк (Россия)' },
  { value: '100000000080', label: 'АКБ Алмазэргиэнбанк' },
  { value: '100000000122', label: 'ИК Банк' },
  { value: '100000000124', label: 'БАНК ОРЕНБУРГ' },
  { value: '100000000118', label: 'КБ АГРОПРОМКРЕДИТ' },
  { value: '100000000159', label: 'АКБ Энергобанк' },
  { value: '100000000146', label: 'КОШЕЛЕВ-БАНК' },
  { value: '100000000069', label: 'СДМ-Банк' },
  { value: '100000000140', label: 'МБ Банк' },
  { value: '100000000047', label: 'АКБ Абсолют Банк' },
  { value: '100000000099', label: 'КБ Модульбанк' },
  { value: '100000000135', label: 'Банк Акцепт' },
  { value: '100000000139', label: 'КБ ЭНЕРГОТРАНСБАНК' },
  { value: '100000000166', label: 'СИБСОЦБАНК' },
  { value: '100000000172', label: 'Банк Развитие-Столица' },
  { value: '100000000187', label: 'Банк РЕСО Кредит' },
  { value: '100000000022', label: 'НКО ЮМани' },
  { value: '100000000029', label: 'Банк Санкт-Петербург' },
  { value: '100000000050', label: 'КБ Кубань Кредит' },
  { value: '100000000177', label: 'Банк НОВИКОМ (НОВИКОМБАНК)' },
  { value: '100000000084', label: 'РосДорБанк' },
  { value: '100000000088', label: 'СКБ Приморья Примсоцбанк' },
  { value: '100000000126', label: 'Банк Саратов' },
  { value: '100000000173', label: 'Таврический Банк' },
  { value: '100000000152', label: 'Тольяттихимбанк' },
  { value: '100000000201', label: 'Банк Кремлевский' },
  { value: '100000000034', label: 'ТКБ БАНК' },
  { value: '100000000094', label: 'ЧЕЛЯБИНВЕСТБАНК' },
  { value: '100000000235', label: 'АКБ Держава' },
  { value: '100000000134', label: 'НБД-Банк' },
  { value: '100000000091', label: 'БАНК СНГБ' },
  { value: '100000000258', label: 'АИКБ Енисейский объединенный банк' },
  { value: '100000000153', label: 'Банк Венец' },
  { value: '100000000016', label: 'Почта Банк' },
  { value: '100000000014', label: 'Банк Русский Стандарт' },
  { value: '100000000083', label: 'Дальневосточный банк' },
  { value: '100000000170', label: 'Банк Интеза' },
  { value: '100000000037', label: 'ГЕНБАНК' },
  { value: '100000000215', label: 'ВУЗ-банк' },
  { value: '100000000142', label: 'УРАЛПРОМБАНК' },
  { value: '100000000243', label: 'Банк Национальный стандарт' },
  { value: '100000000090', label: 'Банк Екатеринбург' },
  { value: '100000000171', label: 'МОРСКОЙ БАНК' },
  { value: '100000000064', label: 'Кредит Урал Банк' },
  { value: '100000000093', label: 'Углеметбанк' },
  { value: '100000000253', label: 'Авто Финанс Банк' },
  { value: '100000000193', label: 'КБ СТРОЙЛЕСБАНК' },
  { value: '100000000158', label: 'Банк ИТУРУП' },
  { value: '100000000174', label: 'Первый Инвестиционный Банк' },
  { value: '100000000183', label: 'Газтрансбанк' },
  { value: '100000000106', label: 'ЧЕЛИНДБАНК' },
  { value: '100000000115', label: 'НИКО-БАНК' },
  { value: '100000000062', label: 'НОКССБАНК' },
  { value: '100000000058', label: 'ВЛАДБИЗНЕСБАНК' },
  { value: '100000000195', label: 'Кузнецкбизнесбанк' },
  { value: '100000000206', label: 'Томскпромстройбанк' },
  { value: '100000000203', label: 'АКБ МЕЖДУНАРОДНЫЙ ФИНАНСОВЫЙ КЛУБ' },
  { value: '100000000081', label: 'АКБ Форштадт' },
  { value: '100000000230', label: 'АКБ Солид' },
  { value: '100000000211', label: 'Банк АЛЕКСАНДРОВСКИЙ' },
  { value: '100000000107', label: 'АКИБАНК' },
  { value: '100000000185', label: 'Нацинвестпромбанк' },
  { value: '100000000113', label: 'АКБ Алеф-Банк' },
  { value: '100000000248', label: 'КБ ВНЕШФИНБАНК' },
  { value: '100000000151', label: 'КБ Урал ФД' },
  { value: '100000000129', label: 'КБ АРЕСБАНК' },
  { value: '100000000208', label: 'Северный Народный Банк' },
  { value: '100000000182', label: 'Банк Объединенный капитал' },
  { value: '100000000189', label: 'ТАТСОЦБАНК' },
  { value: '100000000202', label: 'Норвик Банк' },
  { value: '100000000071', label: 'НС Банк' },
  { value: '100000000066', label: 'Земский банк' },
  { value: '100000000154', label: 'Банк Аверс' },
  { value: '100000000194', label: 'КБ РУСНАРБАНК' },
  { value: '100000000232', label: 'РЕАЛИСТ БАНК' },
  { value: '100000000105', label: 'Эс-Би-Ай Банк' },
  { value: '100000000136', label: 'МЕТКОМБАНК' },
  { value: '100000000191', label: 'КБЭР Банк Казани' },
  { value: '100000000197', label: 'АКБ Трансстройбанк' },
  { value: '100000000205', label: 'Банк Заречье' },
  { value: '100000000059', label: 'КБ Центр-инвест' },
  { value: '100000000070', label: 'Датабанк' },
  { value: '100000000112', label: 'КБ Гарант-Инвест' },
  { value: '100000000223', label: 'СОЦИУМ-БАНК' },
  { value: '100000000148', label: 'КБ СИНКО-БАНК' },
  { value: '100000000199', label: 'ИШБАНК' },
  { value: '100000000045', label: 'Банк ЗЕНИТ' },
  { value: '100000000217', label: 'АКБ ФОРА-БАНК' },
  { value: '100000000169', label: 'МП Банк' },
  { value: '100000000227', label: 'Банк БКФ' },
  { value: '100000000125', label: 'ГОРБАНК' },
  { value: '100000000176', label: 'МОСКОМБАНК' },
  { value: '100000000144', label: 'Тимер Банк' },
  { value: '100000000128', label: 'КБ Ситибанк' },
  { value: '100000000181', label: 'Автоторгбанк' },
  { value: '100000000026', label: 'БАНК УРАЛСИБ' },
  { value: '100000000078', label: 'Ингосстрах Банк' },
  { value: '100000000165', label: 'Русьуниверсалбанк' },
  { value: '100000000031', label: 'КБ УБРиР' },
  { value: '100000000226', label: 'АКБ Приморье' },
  { value: '100000000236', label: 'Банк ИПБ' },
  { value: '100000000103', label: 'КБ Пойдём!' },
  { value: '100000000175', label: 'АКБ ТЕНДЕР-БАНК' },
  { value: '100000000018', label: 'ОТП Банк' },
  { value: '100000000212', label: 'КБ Крокус-Банк' },
  { value: '100000000024', label: 'Хоум кредит' },
  { value: '100000000032', label: 'КБ Ренессанс Кредит' },
  { value: '100000000225', label: 'УКБ Белгородсоцбанк' },
  { value: '100000000272', label: 'Хайс Банк' },
  { value: '100000000219', label: 'Севергазбанк' },
  { value: '100000000184', label: 'АКБ НРБанк' },
  { value: '100000000110', label: 'КБ Москоммерцбанк' },
  { value: '100000000180', label: 'Кубаньторгбанк' },
  { value: '100000000222', label: 'УКБ Новобанк' },
  { value: '100000000233', label: 'НК Банк' },
  { value: '100000000138', label: 'Тойота Банк' },
  { value: '100000000133', label: 'ББР Банк' },
  { value: '100000000245', label: 'АКБ Ланта-Банк' },
  { value: '100000000270', label: 'КБ Долинск' },
  { value: '100000000216', label: 'Банк Финсервис' },
  { value: '100000000042', label: 'КБ ЮНИСТРИМ' },
  { value: '100000000067', label: 'КБ Новый век' },
  { value: '100000000192', label: 'Банк МБА-МОСКВА' },
  { value: '100000000041', label: 'БКС Банк' },
  { value: '100000000200', label: 'АКБ СЛАВИЯ' },
  { value: '100000000167', label: 'АКБ ЕВРОФИНАНС МОСНАРБАНК' },
  { value: '100000000150', label: 'Яндекс Банк' },
  { value: '100000000260', label: 'Банк БЖФ' },
  { value: '100000000161', label: 'КБ ЛОКО-Банк' },
  { value: '100000000234', label: 'БАНК МОСКВА-СИТИ' },
  { value: '100000000250', label: 'Драйв Клик Банк' },
  { value: '100000000149', label: 'ГУТА-БАНК' },
  { value: '100000000278', label: 'ФИНСТАР БАНК' },
  { value: '100000000247', label: 'банк Раунд' },
  { value: '100000000228', label: 'Прио-Внешторгбанк' },
  { value: '100000000196', label: 'Инбанк' },
  { value: '100000000096', label: 'Уралфинанс' },
  { value: '100000000102', label: 'Банк Агророс' },
  { value: '100000000160', label: 'ЮГ-Инвестбанк' },
  { value: '100000000231', label: 'ЦентроКредит' },
  { value: '100000000163', label: 'Снежинский' },
  { value: '100000000040', label: 'Банк ФИНАМ' },
  { value: '100000000284', label: 'Банк Точка' },
  { value: '100000000266', label: 'банк Элита' },
  { value: '100000000046', label: 'Металлинвестбанк' },
  { value: '100000000117', label: 'ПроБанк' },
  { value: '100000000265', label: 'Цифра банк' },
  { value: '100000000273', label: 'Озон Банк (Ozon)' },
  { value: '100000000137', label: 'Первый Дортрансбанк' },
  { value: '100000000282', label: 'ЦМРБанк' },
  { value: '100000000296', label: 'Плайт' },
  { value: '100000000286', label: 'Банк Оранжевый' },
  { value: '100000000293', label: 'ИКБР ЯРИНТЕРБАНК' },
  { value: '100000000288', label: 'РНКО Платежный конструктор' },
  { value: '100000000108', label: 'Азиатско-Тихоокеанский Банк' },
]