import {
  IResourceComponentsProps,
  useCan,
  useShow,
  useMany,
  useTranslate,
} from '@refinedev/core'
import { ListButton, EditButton, Show } from '@refinedev/antd'
import { Descriptions, Tabs, TabsProps, Space, Skeleton } from 'antd'

import { compactArray } from 'utils'
import { IUser, IMerchant } from 'interfaces'
import { CopyText } from 'components/CopyText'
import DateField from 'components/DateField'
import BooleanTag from 'components/BooleanTag'
import { MerchantsList } from './MerchantsList'
import { AccountsList } from './AccountsList'
import { OperationsList } from './OperationsList'
import { CardsList } from './CardsList'
import { EventsList } from './EventsList'
import UserDealing from 'components/UserDealing'

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IUser>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const merchantsIds = compactArray(record?.dealings.map((dealings) => dealings.merchant_id) ?? [])
  const { data: merchantsData, isLoading: merchantsLoading } = useMany<IMerchant>({
    resource: 'admin/merchants',
    ids: merchantsIds,
    meta: {
      filters: [
        {
          field: 'short',
          operator: 'eq',
          value: true,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 100,
      },
    },
    queryOptions: {
      enabled: merchantsIds.length > 0,
    },
  })

  const { data: canListMerchants } = useCan({
    resource: 'admin/merchants',
    action: 'list',
  })
  const { data: canListAccounts } = useCan({
    resource: 'accounts',
    action: 'list',
  })
  const { data: canListOperations } = useCan({
    resource: 'admin/operations',
    action: 'list',
  })
  const { data: canListCards } = useCan({
    resource: 'admin/cards',
    action: 'list',
  })
  const { data: canListEvents } = useCan({
    resource: 'admin/events',
    action: 'list',
  })

  let tabsItems: TabsProps['items'] = []
  canListAccounts?.can &&
    tabsItems.push({
      key: '1',
      label: t('pages.users.titles.accounts'),
      children: record && !isLoading ? <AccountsList userId={record.id} /> : null,
    })
  canListEvents?.can &&
    tabsItems.push({
      key: '2',
      label: t('pages.users.titles.events'),
      children: record && !isLoading ? <EventsList userId={record.id} /> : null,
    })
  canListMerchants?.can && merchantsIds.length > 0 &&
    tabsItems.push({
      key: '3',
      label: t('pages.users.titles.merchant_management'),
      children: record && !isLoading ? <MerchantsList userId={record.id} /> : null,
    })
  canListOperations?.can &&
    tabsItems.push({
      key: '4',
      label: t('pages.users.titles.operations'),
      children: record && !isLoading ? <OperationsList userId={record.id} /> : null,
    })
  canListCards?.can &&
    tabsItems.push({
      key: '5',
      label: t('pages.users.titles.cards'),
      children: record && !isLoading ? <CardsList userId={record.id} /> : null,
    })

  const headerButtons = () => {
    return (
      <>
        <ListButton
          resource="users"
          title={t('users.users')}
        />
        {record &&
          <EditButton
            type='primary'
            resource="users"
            recordItemId={record.id}
            title={t('buttons.edit')}
          />
        }
      </>
    )
  }

  return (
    <>
      <Show
        headerButtons={headerButtons}
        resource="users"
        isLoading={isLoading}
        title={record?.phone || ''}
      >
        <Descriptions column={1}>
          <Descriptions.Item label={t('pages.users.fields.id')}>
            <CopyText value={record?.id} />
          </Descriptions.Item>
          <Descriptions.Item label={t('pages.users.fields.created_at')}>
            <DateField value={record?.created_at} />
          </Descriptions.Item>
          <Descriptions.Item label={t('pages.users.fields.telegram_id')}>
            <CopyText value={record?.telegram_id} />
          </Descriptions.Item>
          <Descriptions.Item label={t('pages.users.fields.verified')}>
            <BooleanTag value={record?.verified} />
          </Descriptions.Item>
          <Descriptions.Item label={t('pages.users.fields.trusted')}>
            <BooleanTag value={record?.trusted} />
          </Descriptions.Item>
          <Descriptions.Item label={t('pages.users.fields.email')}>
            <CopyText value={record?.email} />
          </Descriptions.Item>
          <Descriptions.Item label={t('pages.users.fields.two_factor_verification_enabled')}>
            <BooleanTag value={record?.two_factor_verification_enabled} />
          </Descriptions.Item>
          <Descriptions.Item label={t('pages.users.fields.dealings')}>
            {merchantsIds.length && merchantsLoading ? (
              <Skeleton.Input active />
            ) : (
              <Space wrap>
                {record?.dealings.map((dealing) => (
                  <UserDealing
                    readonly={true}
                    key={dealing.id}
                    dealing={dealing}
                    merchantsData={merchantsData}
                  />
                ))}
              </Space>
            )}
          </Descriptions.Item>
        </Descriptions>
      </Show>
      <Tabs defaultActiveKey="1" items={tabsItems} />
    </>
  )
}
