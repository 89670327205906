import React from 'react';
import { useGetIdentity, useTranslate, useApiUrl, useCustomMutation, useCustom } from '@refinedev/core'
import { Flex, Typography, QRCode, Form } from 'antd'
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useState, useEffect } from 'react';

import { USER_KEY } from '../../constants';
import { ICurrentUser } from 'interfaces';
import { Wrapper } from 'components/client_ui/Wrapper'
import { Card } from 'components/client_ui/Card'
import { CloseBtn } from 'components/client_ui/CloseBtn';
import { BackBtn } from 'components/client_ui/BackBtn';
import { PersonHeader } from 'components/client_ui/PersonHeader';
import { GooglePlayIcon, AppStoreIcon } from './icons';
import { SubmitButton } from 'components/client_ui/SubmitButton';
import { TextJost } from 'components/client_ui/TextJost';
import { InputTwoFA } from 'components/client_ui/Inputs';

const { Text } = Typography

interface OtpSecretResponse {
  secret: string
  provisioning_uri: string
}

const Info: React.FC<{
  header?: string
  text?: string
  gap?: number
}> = ({
  header,
  text,
  gap = 20
}) => {
    return (
      <Flex
        vertical
        gap={gap}
      >
        {header && <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 12,
            fontWeight: '700',
            lineHeight: '17.34px',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#303030',
          }}
        >
          {header}
        </Text>}
        {text && <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 16,
            fontWeight: '400',
            lineHeight: '23.12px',
            color: '#303030',
          }}
        >
          {text}
        </Text>}
      </Flex>
    )
  }

interface CurrentState {
  secret: string
  provisioning_uri: string
  code: string
}

export const TwoFASwitchShow: React.FC = () => {
  const t = useTranslate()
  const { data: user } = useGetIdentity<ICurrentUser>()
  const navigate = useNavigate();

  const [form] = Form.useForm()

  const queryClient = useQueryClient()
  const apiUrl = useApiUrl()
  const { mutate: customMutate } = useCustomMutation<OtpSecretResponse>()

  const { data, isLoading } = useCustom<OtpSecretResponse>({
    url: `${apiUrl}/profile/generate_otp_secret`,
    method: 'post',
    queryOptions: {
      enabled: !user?.two_factor_verification_enabled,
      retry: false
    }
  })

  const [currentState, setCurrentState] = useState<CurrentState>({
    secret: '',
    provisioning_uri: '',
    code: '',
  })

  useEffect(() => {
    setCurrentState((prevState) => ({
      ...prevState,
      secret: data?.data?.secret ?? '',
      provisioning_uri: data?.data?.provisioning_uri ?? '',
    }))
  }, [data])

  const handleFormChange = (field: keyof CurrentState) => (value: string) => {
    setCurrentState((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const enable_two_factor = () => {
    customMutate(
      {
        url: `${apiUrl}/profile/enable_two_factor`,
        method: 'post',
        values: {
          code: currentState.code,
          secret: currentState.secret,
        },
      },
      {
        onError: (error) => { },
        onSuccess: (responce) => {
          let userInfo = localStorage.getItem(USER_KEY)
          if (userInfo) {
            localStorage.setItem(
              USER_KEY,
              JSON.stringify(
                Object.assign(JSON.parse(userInfo), {
                  two_factor_verification_enabled: true,
                })
              )
            )
          }

          queryClient.invalidateQueries(['getUserIdentity'])
          navigate('/twofa')
        },
      }
    )
  }

  const disable_two_factor = () => {
    customMutate(
      {
        url: `${apiUrl}/profile/disable_two_factor`,
        method: 'post',
        values: {
          code: currentState.code,
        },
      },
      {
        onError: (error) => { },
        onSuccess: (responce) => {
          let userInfo = localStorage.getItem(USER_KEY)
          if (userInfo) {
            localStorage.setItem(
              USER_KEY,
              JSON.stringify(
                Object.assign(JSON.parse(userInfo), {
                  two_factor_verification_enabled: false,
                })
              )
            )
          }

          queryClient.invalidateQueries(['getUserIdentity'])
          navigate('/twofa')
        },
      }
    )
  }

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.6,
        ease: 'easeInOut',
      }}

      style={{
        position: 'relative',
        minHeight: '100vh',
        background: 'linear-gradient(258.2deg, #DDF7FF 0%, #C5DCFF 100%)',
      }}
    >
      <Wrapper
        style={{
          padding: '48px 8px 10px 8px',
        }}
      >
        <Card
          style={{
            position: 'relative',
            padding: 12,
            minHeight: 'calc(100vh - 58px)',
            boxShadow: '0px 0px 10px 0px #00000026',
            paddingBottom: 120,
          }}
        >
          <CloseBtn
            style={{
              position: 'absolute',
              top: 12,
              right: 12,
              zIndex: 1,
            }}
          />

          <BackBtn
            style={{
              position: 'absolute',
              bottom: 50,
              left: '50%',
              transform: 'translate(-50%)',
              zIndex: 1,
            }}
          />

          <Flex
            vertical
            gap={20}
          >
            {user?.phone && <PersonHeader user={user} />}
            <Flex
              vertical
              gap={24}
              style={{
                padding: 12,
              }}
            >
              <Info
                header={t('pages.twofa_switch.titles.google_authenticator')}
                text={t('pages.twofa_switch.titles.twofa_switch_info')}
              />
              {!user?.two_factor_verification_enabled ? (
                <>
                  <Flex
                    vertical
                    gap={16}
                  >
                    <Info
                      header={t('pages.twofa_switch.titles.download')}
                    />
                    <Flex
                      gap={20}
                    >
                      <GooglePlayIcon />
                      <AppStoreIcon />
                    </Flex>
                  </Flex>
                  <Info
                    header={t('pages.twofa_switch.titles.connect')}
                    text={t('pages.twofa_switch.titles.scan_qrcode')}
                    gap={16}
                  />
                  {currentState?.provisioning_uri &&
                    <Flex
                      vertical
                      gap={16}
                    >
                      <QRCode
                        value={currentState?.provisioning_uri}
                        size={200}
                        style={{
                          margin: 'auto'
                        }}
                      />
                      <TextJost
                        fontWeight='800'
                        fontSize={12}
                        copyable
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {currentState?.secret}
                      </TextJost>
                      <div
                        style={{
                          height: 1,
                          background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
                        }}
                      />
                    </Flex>
                  }
                  <Info
                    header={t('pages.twofa_switch.titles.enter_code')}
                  />
                </>
              ) : (
                <>
                  <Info
                    header={t('pages.twofa_switch.titles.disable_twofa')}
                    text={t('pages.twofa_switch.titles.code')}
                    gap={16}
                  />
                </>
              )}

              <Form
                layout="vertical"
                form={form}
                onFinish={() => user?.two_factor_verification_enabled ? disable_two_factor() : enable_two_factor()}
                requiredMark={false}
                initialValues={{}}
              >
                <Form.Item
                  name="code"
                  rules={[
                    { required: true, message: '', },
                    { pattern: /^\d{6}$/, message: '', }
                  ]}
                  style={{
                    textAlign: 'center',
                    marginBottom: 40
                  }}
                >
                  <InputTwoFA
                    value={currentState.code}
                    onChange={handleFormChange('code')}
                    maxLength={6}
                    placeholder={t('pages.twofa_switch.placeholders.authenticator_code')}
                    style={{
                      textAlign: 'center',
                      fontWeight: '700',
                      width: '100%',
                      maxWidth: 320,
                      margin: 'auto'
                    }}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <SubmitButton
                    form={form}
                  />
                </Form.Item>
              </Form>
            </Flex>
          </Flex>
        </Card>
      </Wrapper>
    </motion.div >
  )
}
