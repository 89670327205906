import React from 'react'
import { useState, useEffect } from 'react';
import type { CSSProperties } from "react";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
  useApiUrl,
  useCustomMutation
} from '@refinedev/core'
import { Flex, Spin, Typography } from 'antd'
import { IInvoice } from 'interfaces'
import { motion } from "framer-motion"
import { Wrapper } from 'components/client_ui/Wrapper'
import { TextJost } from 'components/client_ui/TextJost'
import { RUBIcon, CloseIcon, CheckIcon } from './icons'
import { HomeButton } from 'components/client_ui/HomeButton'
import { formatAmount, formatCurrency, statusColor } from 'components/client_ui/utils'
import { FormatedDate } from 'components/client_ui/FormatedDate';
import { BackButton } from './back_btn';
import { PayButton } from './pay_button';

const paidStyle: CSSProperties = {
  background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
  boxShadow: '0px 0px 120px 0px #75B3FF',
}
const expiredStyle: CSSProperties = {
  background: '#F28754',
  boxShadow: '0px 0px 120px 0px #F28754',
}

const CurrencyIcon: React.FC<{ currency?: string; }> = ({ currency }) => {
  switch (currency?.toUpperCase()) {
    case 'RUB':
      return <RUBIcon />
    default:
      return <></>
  }
}

export const InvoiceShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const apiUrl = useApiUrl()
  const { mutate: customMutate } = useCustomMutation<IInvoice>()

  const { queryResult } = useShow<IInvoice>()
  const { data, isLoading: isFetchingInvoice } = queryResult

  const [isLoading, setIsLoading] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState<IInvoice | undefined>(undefined)

  useEffect(() => {
    setCurrentInvoice(data?.data)
  }, [data?.data])

  const iconStyle = currentInvoice?.status === 'paid' ? paidStyle : expiredStyle

  const payInvoice = () => {
    if (isLoading || isFetchingInvoice) return
    setIsLoading(true)

    customMutate(
      {
        url: `${apiUrl}/invoices/${currentInvoice?.id}/pay`,
        method: 'post',
        values: {},
      },
      {
        onError: (error) => {
          setIsLoading(false)
        },
        onSuccess: (response) => {
          setIsLoading(false)
          setCurrentInvoice(response?.data)
        },
      }
    )
  }

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.35,
        ease: 'easeInOut',
      }}

      style={{
        position: 'relative',
        minHeight: '100vh',
        background: '#FFFFFF',
      }}
    >
      <Wrapper
        style={{
          padding: '48px 32px 10px 32px',
          minHeight: '100vh',
        }}
      >
        {(isFetchingInvoice || isLoading) &&
          <Spin
            size="large"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 5,
            }}
          />
        }

        {!isFetchingInvoice && !isLoading && currentInvoice &&
          <>
            <Flex
              vertical
              gap={64}
            >
              {currentInvoice.status !== 'created' ? (
                <Flex
                  justify='center'
                  align='center'
                  style={{
                    height: 120,
                    width: 120,
                    borderRadius: '50%',
                    margin: '0 auto',
                    ...iconStyle,
                  }}
                >
                  {currentInvoice.status === 'paid' ? <CheckIcon /> : <CloseIcon />}
                </Flex>
              ) : (
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                  }}
                >
                  <BackButton />
                  <TextJost fontWeight='700' fontSize={16}>{t('pages.invoices.titles.paying_invoice')}</TextJost>
                </div>
              )}
              <Flex
                vertical
                gap={64}
                style={{
                  margin: '0 auto'
                }}
              >
                <Flex
                  vertical
                  gap={16}
                >
                  <Flex vertical gap={8}>
                    <TextJost fontWeight='500'>{t('pages.invoices.titles.description')}</TextJost>
                    <TextJost fontWeight='800'>
                      {t('pages.invoices.titles.payment_for_services')}&nbsp;{currentInvoice?.merchant?.name}
                    </TextJost>
                  </Flex>
                  <Flex vertical gap={8}>
                    <TextJost fontWeight='500'>{t('pages.invoices.titles.operation_status')}</TextJost>
                    <TextJost fontWeight='800' color={statusColor(currentInvoice.status)}>
                      {t('components.invoices_list.statuses.' + currentInvoice.status)}
                    </TextJost>
                  </Flex>
                  <Flex vertical gap={8}>
                    <TextJost fontWeight='500'>{t('pages.invoices.titles.date')}</TextJost>
                    <TextJost fontWeight='800'>
                      <FormatedDate format='DD.MM.YYYY HH:mm:ss' value={currentInvoice?.created_at} />
                    </TextJost>
                  </Flex>
                  <Flex vertical gap={8}>
                    <TextJost fontWeight='500'>{t('pages.invoices.titles.invoice_id')}</TextJost>
                    <TextJost fontSize={12} fontWeight='800'>{currentInvoice.id}</TextJost>
                  </Flex>
                  <Flex vertical gap={8}>
                    <TextJost fontWeight='500'>{t('pages.invoices.titles.amount')}</TextJost>
                    <Flex align='center'>
                      <TextJost fontSize={32} fontWeight='800'>{formatAmount(currentInvoice.amount)}</TextJost>
                      <CurrencyIcon currency={currentInvoice?.merchant?.currency?.code} />
                    </Flex>
                  </Flex>
                </Flex>
                {currentInvoice.status !== 'created' ? (
                  <HomeButton />
                ) : (
                  <PayButton
                    onClick={payInvoice}
                    invoice={currentInvoice}
                  />
                )}
              </Flex>
            </Flex>
          </>
        }
      </Wrapper>
    </motion.div>
  )
}
