import React from 'react'
import { useTranslate } from '@refinedev/core'
import { Tag } from 'antd'

export const AgreementKind: React.FC<{ kind: string | number }> = ({ kind }) => {
  const t = useTranslate()

  const getColor = (kind: string | number): string => {
    switch (kind) {
      case 'merchant_income':
        return 'green'
      case 'merchant_outcome':
        return 'red'
      case 'agent_income':
        return 'blue'
      case 'agent_outcome':
        return 'magenta'
      case 'payment_provider_income':
        return 'geekblue'
      case 'payment_provider_outcome':
        return 'orange'

      default:
        return 'default'
    }
  }

  return (
    <Tag
      color={getColor(kind)}
    >
      {t('components.agreement_kind.kinds.' + kind)}
    </Tag>
  )
}
