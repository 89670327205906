import React from 'react';
import { useGetIdentity, useTranslate, useApiUrl, useCustomMutation, useLogout } from '@refinedev/core'
import { Flex, Typography, Input, Form } from 'antd'
import { motion } from "framer-motion";
import { useState } from 'react';

import { ICurrentUser } from 'interfaces';
import { Wrapper } from 'components/client_ui/Wrapper'
import { Card } from 'components/client_ui/Card'
import { CloseBtn } from 'components/client_ui/CloseBtn';
import { BackBtn } from 'components/client_ui/BackBtn';
import { PersonHeader } from 'components/client_ui/PersonHeader';
import { SubmitButton } from 'components/client_ui/SubmitButton';
import { InputTwoFA } from 'components/client_ui/Inputs';
import { Checker } from "components/client_ui/Checker";

const { Text } = Typography

export const ChangePasswordShow: React.FC = () => {
  const t = useTranslate()
  const { data: user } = useGetIdentity<ICurrentUser>()

  const [form] = Form.useForm()

  const [capital, setCapital] = useState<number>(0);
  const [digit, setDigit] = useState<number>(0);
  const [special, setSpecial] = useState<number>(0);
  const [length, setLength] = useState<number>(0);
  const [confirmation, setConfirmation] = useState<number>(0);

  const checkPassword = (_: any, value: string) => {
    if (!value) {
      setCapital(0);
      setDigit(0);
      setSpecial(0);
      setLength(0);
      return Promise.resolve();
    }

    const capital = value.match(/[A-Z]/);
    const digit = value.match(/[0-9]/);
    const special = value.match(/[@#$%^&+=!*()\-~]/);
    const length = value.length >= 8

    capital ? setCapital(2) : setCapital(1);
    digit ? setDigit(2) : setDigit(1);
    special ? setSpecial(2) : setSpecial(1);
    length ? setLength(2) : setLength(1);

    if (capital && digit && special && length) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(''));
  };

  const checkConfirmation = (_: any, value: string) => {
    if (!value) {
      setConfirmation(0);
      return Promise.resolve();
    }
    if (form.getFieldValue('new_password') === value) {
      setConfirmation(2);
      return Promise.resolve();
    }
    setConfirmation(1);
    return Promise.reject(new Error(''));
  };

  const apiUrl = useApiUrl()
  const { mutate: customMutate } = useCustomMutation()
  const { mutate: mutateLogout } = useLogout()

  const change_password = () => {
    customMutate(
      {
        url: `${apiUrl}/profile/change_password`,
        method: 'post',
        values: {
          password: form.getFieldValue('password'),
          new_password: form.getFieldValue('new_password'),
          ...(user?.two_factor_verification_enabled && { code: form.getFieldValue('code'), })
        },
      },
      {
        onError: (error) => { },
        onSuccess: (responce) => {
          mutateLogout()
        },
      }
    )
  }

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.6,
        ease: 'easeInOut',
      }}

      style={{
        position: 'relative',
        minHeight: '100vh',
        background: 'linear-gradient(258.2deg, #DDF7FF 0%, #C5DCFF 100%)',
      }}
    >
      <Wrapper
        style={{
          padding: '48px 8px 10px 8px',
        }}
      >
        <Card
          style={{
            position: 'relative',
            padding: 12,
            minHeight: 'calc(100vh - 58px)',
            boxShadow: '0px 0px 10px 0px #00000026',
            paddingBottom: 120,
          }}
        >
          <CloseBtn
            style={{
              position: 'absolute',
              top: 12,
              right: 12,
              zIndex: 1,
            }}
          />

          <BackBtn
            style={{
              position: 'absolute',
              bottom: 50,
              left: '50%',
              transform: 'translate(-50%)',
              zIndex: 1,
            }}
          />

          <Flex
            vertical
            gap={20}
          >
            {user?.phone && <PersonHeader user={user} />}
            <Flex
              vertical
              gap={40}
              style={{
                padding: 12,
              }}
            >
              <Text
                style={{
                  fontFamily: 'Jost',
                  fontSize: 12,
                  fontWeight: '700',
                  lineHeight: '17.34px',
                  letterSpacing: '0.1em',
                  textTransform: 'uppercase',
                  color: '#303030',
                }}
              >
                {t('pages.change_password.titles.change_password')}
              </Text>
              <Form
                layout="vertical"
                form={form}
                onFinish={() => change_password()}
                requiredMark={false}
                initialValues={{}}
              >
                <Flex
                  vertical
                  align='center'
                  gap={48}
                >
                  <Flex
                    vertical
                  // gap={32}
                  >
                    {user?.two_factor_verification_enabled &&
                      <Flex
                        vertical
                        gap={8}
                        align='center'
                      >
                        <Text
                          style={{
                            height: 20,
                            fontFamily: 'Jost',
                            fontSize: 14,
                            fontWeight: '500',
                            lineHeight: '20.23px',
                            color: '#303030',
                          }}
                        >
                          {t('pages.change_password.fields.code')}
                        </Text>
                        <Form.Item
                          name="code"
                          rules={[
                            { required: true, message: '', },
                            { pattern: /^\d{6}$/, message: '', }
                          ]}
                          style={{
                            textAlign: 'center'
                          }}
                        >
                          <InputTwoFA
                            value=''
                            onChange={() => { }}
                            maxLength={6}
                            placeholder={t('pages.change_password.placeholders.authenticator_code')}
                            style={{
                              textAlign: 'center',
                              fontWeight: '700',
                              width: 306,
                              margin: 'auto'
                            }}
                          />
                        </Form.Item>
                      </Flex>
                    }
                    <Flex
                      vertical
                      gap={8}
                      align='center'
                    >
                      <Text
                        style={{
                          height: 20,
                          fontFamily: 'Jost',
                          fontSize: 14,
                          fontWeight: '500',
                          lineHeight: '20.23px',
                          color: '#303030',
                        }}
                      >
                        {t('pages.change_password.fields.current_password')}
                      </Text>
                      <Form.Item
                        name='password'
                        rules={[
                          {
                            required: true, message: '',
                          },
                          {
                            min: 8, max: 64, message: '',
                          },
                        ]}
                        style={{
                          textAlign: 'center',
                          fontFamily: 'Jost',
                          fontSize: 20,
                          fontWeight: '800',
                          lineHeight: '28.9px',
                          color: '#303030',
                        }}
                      >
                        <Input
                          key='password'
                          type='password'
                          autoComplete="off"
                          maxLength={64}
                          placeholder='●●●●●●●●●●●●●●●'
                          style={{
                            textAlign: 'center',
                            height: 45,
                            width: 306,
                            padding: '8px 16px',
                            borderRadius: 24,
                            border: '1px solid #EEEEEE',
                            fontFamily: 'Jost',
                            fontSize: 20,
                            fontWeight: '800',
                            lineHeight: '28.9px',
                            color: '#303030',
                            outline: 0,
                            outlineOffset: 0,
                          }}
                        />
                      </Form.Item>
                    </Flex>
                    <Flex
                      vertical
                      gap={8}
                      align='center'
                    >
                      <Text
                        style={{
                          height: 20,
                          fontFamily: 'Jost',
                          fontSize: 14,
                          fontWeight: '500',
                          lineHeight: '20.23px',
                          color: '#303030',
                        }}
                      >
                        {t('pages.change_password.fields.new_password')}
                      </Text>
                      <Form.Item
                        name='new_password'
                        rules={[
                          {
                            required: true, message: '',
                          },
                          {
                            validator: checkPassword, message: '',
                          },
                        ]}
                        style={{
                          textAlign: 'center',
                          fontFamily: 'Jost',
                          fontSize: 20,
                          fontWeight: '800',
                          lineHeight: '28.9px',
                          color: '#303030',
                        }}
                      >
                        <Input
                          key='password'
                          type='password'
                          autoComplete="off"
                          maxLength={64}
                          placeholder='●●●●●●●●●●●●●●●'
                          style={{
                            textAlign: 'center',
                            height: 45,
                            width: 306,
                            padding: '8px 16px',
                            borderRadius: 24,
                            border: '1px solid #EEEEEE',
                            fontFamily: 'Jost',
                            fontSize: 20,
                            fontWeight: '800',
                            lineHeight: '28.9px',
                            color: '#303030',
                            outline: 0,
                            outlineOffset: 0,
                          }}
                        />
                      </Form.Item>
                    </Flex>
                    <Flex
                      vertical
                      gap={8}
                      align='center'
                    >
                      <Text
                        style={{
                          height: 20,
                          fontFamily: 'Jost',
                          fontSize: 14,
                          fontWeight: '500',
                          lineHeight: '20.23px',
                          color: '#303030',
                        }}
                      >
                        {t('pages.change_password.fields.confirm_password')}
                      </Text>
                      <Form.Item
                        name='confirm_password'
                        dependencies={['password']}
                        rules={[
                          {
                            required: true, message: '',
                          },
                          {
                            validator: checkConfirmation, message: '',
                          },
                        ]}
                        style={{
                          textAlign: 'center',
                          fontFamily: 'Jost',
                          fontSize: 20,
                          fontWeight: '800',
                          lineHeight: '28.9px',
                          color: '#303030',
                        }}
                      >
                        <Input
                          key='confirm_password'
                          type='password'
                          autoComplete="off"
                          maxLength={64}
                          placeholder='●●●●●●●●●●●●●●●'
                          style={{
                            textAlign: 'center',
                            height: 45,
                            width: 306,
                            padding: '8px 16px',
                            borderRadius: 24,
                            border: '1px solid #EEEEEE',
                            fontFamily: 'Jost',
                            fontSize: 20,
                            fontWeight: '800',
                            lineHeight: '28.9px',
                            color: '#303030',
                            outline: 0,
                            outlineOffset: 0,
                          }}
                        />
                      </Form.Item>
                    </Flex>
                    <Flex
                      vertical
                      gap={8}
                    >
                      <Checker status={capital} text={t('pages.signup.titles.password_capital_char')} />
                      <Checker status={digit} text={t('pages.signup.titles.password_digit')} />
                      <Checker status={special} text={t('pages.signup.titles.password_special_char')} />
                      <Checker status={length} text={t('pages.signup.titles.password_length')} />
                      <Checker status={confirmation} text={t('pages.signup.titles.password_confirmation')} />
                    </Flex>
                  </Flex>
                  <Form.Item
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <SubmitButton
                      form={form}
                    />
                  </Form.Item>
                </Flex>
              </Form>
            </Flex>
          </Flex>
        </Card>
      </Wrapper>
    </motion.div >
  )
}
