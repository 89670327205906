import React from 'react';
import { useGetIdentity, useTranslate } from '@refinedev/core'
import { Flex } from 'antd'
import { motion } from "framer-motion";

import { ICurrentUser } from 'interfaces';
import { Wrapper } from 'components/client_ui/Wrapper'
import { Card } from 'components/client_ui/Card'
import { CloseBtn } from 'components/client_ui/CloseBtn';
import { BackBtn } from 'components/client_ui/BackBtn';
import { PersonHeader } from 'components/client_ui/PersonHeader';
import { SubMenu } from 'components/client_ui/SubMenu';

export const SecurityShow: React.FC = () => {
  const t = useTranslate()
  const { data: user } = useGetIdentity<ICurrentUser>()

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.6,
        ease: 'easeInOut',
      }}

      style={{
        position: 'relative',
        minHeight: '100vh',
        background: 'linear-gradient(258.2deg, #DDF7FF 0%, #C5DCFF 100%)',
      }}
    >
      <Wrapper
        style={{
          padding: '48px 8px 10px 8px',
        }}
      >
        <Card
          style={{
            position: 'relative',
            padding: 12,
            minHeight: 'calc(100vh - 58px)',
            boxShadow: '0px 0px 10px 0px #00000026',
            paddingBottom: 120,
          }}
        >
          <CloseBtn
            style={{
              position: 'absolute',
              top: 12,
              right: 12,
              zIndex: 1,
            }}
          />

          <BackBtn
            style={{
              position: 'absolute',
              bottom: 50,
              left: '50%',
              transform: 'translate(-50%)',
              zIndex: 1,
            }}
          />

          <Flex
            vertical
            gap={20}
          >
            {user?.phone && <PersonHeader user={user} />}
            <Flex
              vertical
              gap={35}
              style={{
                padding: 12,
              }}
            >
              <SubMenu
                header={t('pages.security.titles.security')}
                menu={[
                  {
                    name: t('pages.security.titles.change_password'),
                    to: '/change_password',
                    icon: true
                  },
                  {
                    name: t('pages.security.titles.twofa'),
                    to: '/twofa',
                    icon: true
                  },
                ]}
                gap={20}
              />
            </Flex>
          </Flex>
        </Card>
      </Wrapper>
    </motion.div >
  )
}
