import { IResourceComponentsProps, useCan, useTranslate } from '@refinedev/core'
import { ListButton, Edit, useForm } from '@refinedev/antd'
import { Form, Input, Select } from 'antd'
import { timeZoneOptions } from 'utils'
import { IMerchant } from 'interfaces'

export const MerchantEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IMerchant>({
    action: 'edit',
    redirect: 'show',
  })

  const headerButtons = () => {
    return (
      <>
        <ListButton
          resource="admin/merchants"
          title={t('merchants.merchants')}
        />
      </>
    )
  }

  return (
    <Edit
      headerButtons={headerButtons}
      resource="admin/merchants"
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label={t('pages.merchants.fields.name')} name="name">
          <Input />
        </Form.Item>
        <Form.Item name="timezone" label={t('pages.merchants.fields.timezone')}>
          <Select
            showSearch
            options={timeZoneOptions}
          />
        </Form.Item>
      </Form>
    </Edit>
  )
}
