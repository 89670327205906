import React from 'react'
import { invoiceStatus } from 'interfaces'
import { Tag } from 'antd'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'

export const InvoiceStatus: React.FC<{ status: invoiceStatus }> = ({ status }) => {
  const IconMap = {
    created: <SyncOutlined spin />,
    expired: <ClockCircleOutlined />,
    paid: <CheckCircleOutlined />,
  }

  const ColorMap = {
    created: 'processing',
    expired: 'error',
    paid: 'success',
  }

  return (
    <Tag
      icon={IconMap[status]}
      color={ColorMap[status]}
    >
      {status}
    </Tag>
  )
}
