import { useNavigate } from "react-router-dom";
import { Flex } from "antd";

const BackIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_133_8671)">
        <path d="M3 12L7 16L7 13L22 13L22 11L7 11L7 8L3 12Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_133_8671">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BackButton: React.FC<{
  style?: React.CSSProperties
}> = ({
  style = {}
}) => {
    const navigate = useNavigate();

    return (
      <Flex
        onClick={() => navigate(-1)}
        justify='space-around'
        align='center'
        style={Object.assign({
          height: 24,
          width: 24,
          cursor: 'pointer',
        }, style)}
      >
        <BackIcon />
      </Flex>
    )
  }