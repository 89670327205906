import React from 'react';
import { useGetIdentity, useTranslate } from '@refinedev/core'
import { Flex } from 'antd'
import { motion } from "framer-motion";

import { ICurrentUser } from 'interfaces';
import { Wrapper } from 'components/client_ui/Wrapper'
import { Card } from 'components/client_ui/Card'
import { ManageProfile } from 'components/client_ui/ManageProfile';
import { CloseBtn } from 'components/client_ui/CloseBtn';
import { ExitBtn } from 'components/client_ui/ExitBtn';
import { PersonHeader } from 'components/client_ui/PersonHeader';
import { SubMenu } from 'components/client_ui/SubMenu';

export const ProfileShow: React.FC = () => {
  const t = useTranslate()
  const { data: user } = useGetIdentity<ICurrentUser>()

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.35,
        ease: 'easeInOut',
      }}

      style={{
        position: 'relative',
        minHeight: '100vh',
        background: 'linear-gradient(258.2deg, #DDF7FF 0%, #C5DCFF 100%)',
      }}
    >
      <Wrapper
        style={{
          padding: '48px 8px 10px 8px',
        }}
      >
        <Card
          style={{
            position: 'relative',
            padding: 12,
            minHeight: 'calc(100vh - 58px)',
            boxShadow: '0px 0px 10px 0px #00000026',
            paddingBottom: 120,
          }}
        >
          <CloseBtn
            style={{
              position: 'absolute',
              top: 12,
              right: 12,
              zIndex: 1,
            }}
          />
          <ExitBtn
            style={{
              position: 'absolute',
              bottom: 50,
              left: '50%',
              transform: 'translate(-50%)',
              zIndex: 1,
            }}
          />
          <Flex
            vertical
            gap={20}
          >
            {user?.phone && <PersonHeader user={user} />}
            <ManageProfile />
            <Flex
              vertical
              gap={35}
              style={{
                padding: 12,
              }}
            >
              <SubMenu
                header={t('pages.profile.titles.account')}
                menu={[
                  {
                    name: t('pages.profile.titles.verification'),
                    to: '/home',
                    icon: true
                  },
                  {
                    name: t('pages.profile.titles.security'),
                    to: '/security',
                    icon: true
                  },
                ]}
                gap={20}
              />
              <SubMenu
                header={t('pages.profile.titles.linked_accounts')}
                menu={[
                  {
                    name: t('pages.profile.titles.bank_accounts'),
                    to: '/home',
                    icon: true
                  },
                ]}
                gap={20}
              />

              <div
                style={{
                  height: 1,
                  background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
                }}
              />

              <SubMenu
                menu={[
                  {
                    name: t('pages.profile.titles.aboutus'),
                    to: '/aboutus',
                    icon: false
                  },
                  {
                    name: t('pages.profile.titles.rules'),
                    to: '/home',
                    icon: false
                  },
                ]}
                gap={24}
              />
            </Flex>
          </Flex>
        </Card>
      </Wrapper>
    </motion.div >
  )
}
