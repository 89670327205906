import React from 'react';
import { Input } from 'antd';

interface CustomInputProps {
  value: string;
  onChange: (value: string) => void;
  currency?: string;
  placeholder?: string;
  maxLength?: number;
  style?: React.CSSProperties;
  disabled?: boolean
}

export const InputTwoFA = (props: CustomInputProps) => {
  const { value, onChange, style } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const cleaned = inputValue.replace(/\D/g, '')

    if (cleaned?.match(/^\d{0,6}$/)) {
      onChange(cleaned);
    }
  };

  return (
    <Input
      {...props}
      value={value}
      onChange={handleChange}
      autoComplete='off'
      style={Object.assign({
        height: 45,
        padding: '8px 16px',
        borderRadius: 24,
        border: '1px solid #EEEEEE',
        fontFamily: 'Jost',
        fontSize: 20,
        fontWeight: '400',
        lineHeight: '28.9px',
        color: '#303030',
        outline: 0,
        outlineOffset: 0,
      }, style)}
    />
  );
};