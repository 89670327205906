import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { ListButton, Edit, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'
import { IAgent } from 'interfaces'

export const AgentEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IAgent>({
    action: 'edit',
    redirect: 'show',
  })

  const headerButtons = () => {
    return (
      <>
        <ListButton
          resource="admin/agents"
          title={t('agents.agents')}
        />
      </>
    )
  }

  return (
    <Edit
      headerButtons={headerButtons}
      resource="admin/agents"
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label={t('pages.agents.fields.name')} name="name">
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  )
}
