import React from 'react'
import {
  useTranslate,
  useList,
} from '@refinedev/core'
import { Flex, Button, Typography, Space } from 'antd'
import { IInvoice, IAccount } from 'interfaces'
import { useNavigate } from "react-router-dom";
import { formatAmount, formatCurrency } from 'components/client_ui/utils'

const { Text } = Typography

const InvoicePayButton: React.FC<{
  onClick: () => void
  isLoading?: boolean
  currency: string
  amount: string
}> = ({
  onClick,
  isLoading,
  currency,
  amount,
}) => {
    const t = useTranslate()

    return (
      <Button
        onClick={onClick}
        loading={isLoading}
        style={{
          textAlign: 'center',
          height: 56,
          padding: '16px 24px',
          borderRadius: 60,
          background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
          border: 0,
          boxShadow: 'none',
          margin: '0 auto',
        }}
      >
        <Flex
          justify='space-around'
          align='center'
          gap={10}
        >
          <Text
            style={{
              height: 23,
              fontFamily: 'Jost',
              fontSize: 16,
              fontWeight: '700',
              lineHeight: '23.12px',
              color: '#FFFFFF',
            }}
          >
            <Space>
              {t("pages.invoices.buttons.pay")}
              {amount}
              {formatCurrency(currency)}
            </Space>
          </Text>
        </Flex>
      </Button >
    );
  };

const DepositButton: React.FC<{
  isLoading?: boolean
  currency: string
  amount: string
}> = ({
  isLoading,
  currency,
  amount,
}) => {
    const t = useTranslate()
    const navigate = useNavigate();

    return (
      <Button
        onClick={() => navigate('/deposit/' + currency + '?amount=' + amount)}
        loading={isLoading}
        style={{
          textAlign: 'center',
          height: 56,
          padding: '16px 24px',
          borderRadius: 60,
          background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
          border: 0,
          boxShadow: 'none',
          margin: '0 auto',
        }}
      >
        <Flex
          justify='space-around'
          align='center'
          gap={10}
        >
          <Text
            style={{
              height: 23,
              fontFamily: 'Jost',
              fontSize: 16,
              fontWeight: '700',
              lineHeight: '23.12px',
              color: '#FFFFFF',
            }}
          >
            <Space>
              {t("pages.invoices.buttons.deposit")}
              {amount}
              {formatCurrency(currency)}
            </Space>
          </Text>
        </Flex>
      </Button >
    );
  };

export const PayButton: React.FC<{
  onClick: () => void
  invoice: IInvoice
}> = ({
  onClick,
  invoice
}) => {
    const currency_code = invoice?.merchant?.currency?.code;

    const { data: accountsData, isLoading: isFetchingAccount } = useList<IAccount>({
      resource: 'accounts',
      filters: [
        {
          field: "currency_code",
          operator: "eq",
          value: currency_code,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 1,
      },
    })
    const accounts = accountsData?.data ?? [];

    const balance = accounts.length ? accounts[0].balance : '0.00';
    const amount = invoice?.amount;
    const enough_funds = Number(balance) >= Number(amount) ? true : false

    return (
      <>
        {enough_funds ? (
          <InvoicePayButton
            onClick={onClick}
            currency={currency_code}
            amount={amount}
          />
        ) : (
          <DepositButton
            currency={currency_code}
            amount={(Number(amount) - Number(balance)).toFixed(2)}
          />
        )}
      </>
    )
  }